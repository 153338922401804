export const alert = {
  inviteSuccess: {
    title: "User invited",
    text: "User successfully invited",
    type: "success"
  },
  allError: {
    type: "error",
    title: "Oops..."
  },
  updateSucess: {
    title: "User Updated",
    type: "success"
  },
};
