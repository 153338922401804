import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useObservable } from "hooks";
import Table, { TableCol, TableRow } from "new-components/Table";
// import isEqual from "lodash/isEqual";
import loadingIcon from "components/Callback/loading.svg";
import "./AllUsers.css";

export default function AllUsers(props) {
  const users = useObservable(props.data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (() => {
      setLoading(false);
    })();
  }, [users]);

  const mapContent = item => (
    <TableRow key={item.id_account}>
      <TableCol size={35}>
        <Link
          to={{
            pathname: `user/${item.id_account}`,
            state: { ...item }
          }}
        >
          {item.nm_account}
        </Link>
      </TableCol>
      <TableCol size={35}>{item.ds_email}</TableCol>
      <TableCol size={15}>{item.nm_role}</TableCol>
      <TableCol size={15}>
        <Link
          to={{
            pathname: `edit-user/${item.id_account}`,
            state: { ...item }
          }}
        >
          Edit Details
        </Link>
      </TableCol>
    </TableRow>
  );

  const noContet = () => (
    <TableRow cssClass="loading">
      <div className="no-content">
        <span className="title">No users yet</span>
        <span className="text">add users</span>
      </div>
    </TableRow>
  );

  const loadingData = () => (
    <TableRow cssClass="loading">
      <img src={loadingIcon} alt="loading" />
    </TableRow>
  );

  const TableContent = useMemo(() => {
    if (users.length) return users.map(mapContent);
    if (!users.length && !!loading) return noContet();
    if (!users.length && !loading) return loadingData();
  }, [users, loading]);

  return (
    <div className="AllUsers">
      <div className="head">
        <h3 className="title-page">All Users</h3>
        <Link to="/invite-user/" className="main-button">
          Invite user
        </Link>
      </div>
      <Table
        header={[
          ["Name", 35],
          ["Email", 35],
          ["Role", 30]
        ]}
      >
        {TableContent}
      </Table>
    </div>
  );
}
