import React, { Component } from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import actionSimpleApi from "../../actions/actionSimpleAPI";

class Login extends Component {
  componentDidMount() {
    this.goToServices();

  }

  goToServices() {
    if (!this.props.auth.isAuthenticated()) {
      this.props.auth.login();
    } else {
      this.props.getUserID(this.props.id_token, this.props.history.push);
    }
  }

  render() {
    return <div id="manager-dashboard"/>;
  }
}

const mapStateToProps = (state) => ({
  id_token: state.id_token
});

const mapDispatchToProps = (dispatch) => ({
  getUserID: (id_token, push) => {
    actionSimpleApi(id_token, "/account/login", null, 'get')
        .then((resp) => {
          if (resp.status === 200) {
            dispatch({
              type: "ADD_USER",
              data: resp.data
            });

            if (resp.data.id_role) {
              dispatch({
                type: "ADD_USER_LEVEL",
                data: parseInt(resp.data.id_role, 10)
              });
            }
            dispatch({
              type: "HAS_CREDIT_CARD",
              data: resp.data.credit_card
            });
            push("/environments");
          } else {
            push("/create-account");
          }
        })
        .catch((err) => {
          push("/create-account");
        });
  }
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Login)
);
