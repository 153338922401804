import React, { useMemo, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles.css";

export default function CardSelector({
  id,
  name,
  unicChoice,
  title,
  value,
  text,
  children,
  onChange,
}) {
  const labelRef = useRef();

  useEffect(() => {
    (function() {
      const el = labelRef?.current;
      if (el) {
        let width = el.offsetWidth;
        let height = el.offsetHeight;
        el.setAttribute(
          "style",
          ` --height: ${height}px; 
            --width: ${width}px;
            --animationName: ${id}${name};`
        );
      }
    })();
  }, [labelRef]);

  const RenderElement = useMemo(() => {
    let type = unicChoice ? "radio" : "checkbox";
    let deiedRender = !!children && !!text;

    return deiedRender ? (
      <diV className="cardSelector__render-error">
        <span className="cardSelector__render-error-message">
          This component can't be rendered with both children and text props,
          please choose one of them.
        </span>
      </diV>
    ) : (
      <div className="cardSelector-wrapper">
        <input
          type={type}
          id={id}
          name={name}
          defaultValue={value}
          onChange={onChange}
          className="cardSelector-input"
        />
        <label ref={labelRef} htmlFor={id} className="cardSelector-label">
          <div className="cardSelector-label__content">
            <h5 className="cardSelector-label__title">{title}</h5>
            <p className="cardSelector-label__text">{text}</p>
          </div>
        </label>
      </div>
    );
  }, [unicChoice]);
  return RenderElement;
}

CardSelector.defaultProps = {
  text: undefined,
  unicChoice: false,
  children: undefined,
  onChange: (e) => e,
};

CardSelector.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  unicChoice: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
  onChange: PropTypes.func,
};
