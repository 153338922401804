import React, { Component } from "react";
import { connect } from "react-redux";
import Toggle from "../../Toggle";
import Search from "./Search";
import "./Applications.css";

class Applications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: props.applications
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      applications: nextProps.applications
    });
  }
  render() {
    return (
      <div className="Applications">
        {this.state.applications.length > 0 ? (
          <div className="filter-applications">
            <span className="filter-title">Connected Applications</span>
            <div className="filter-field">
              <Search />
              <input type="text" placeholder="Search" />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="applications-list">
          {this.state.applications.map(item => (
            <div className="app" key={item.id_application}>
              <span className="app-name">{item.name}</span>
              <Toggle
                onClick={el => {
                  this.props.onClick(el, item);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applications: state.applications
});

export default connect(mapStateToProps)(Applications);
