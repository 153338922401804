import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import swal from "sweetalert2";
import Menu from "../../components/Menu/Menu";
import SectionHeader from "../../new-components/SectionHeader";
import Container from "../../new-components/Box/Container";
import Input from "../../new-components/Form/Input";
import "./Team.css";

import actionAPI from "../../actions/actionAPI";

class Team extends Component {
  constructor(props) {
    super(props);
    let params = props.match.params;
    this.state = {
      loading: false,
      new_team: params.id ? false : true,
      user_data: params.id ? props.location.state : false,
      field_name: {
        value: params.id ? props.location.state.name : "",
        valid: params.id ? true : false
      },
      user_level: props.user_level
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      user_level: nextProps.user_level
    });
  }
  setField(val, type) {
    this.setState({
      [`field_${type}`]: val
    });
  }
  createTeam() {
    this.setState({ loading: true });
    actionAPI(this.props.id_token, "/team/create", {
      name: this.state.field_name.value
    }).then(resp => {
      this.setState({ loading: false });
      swal.fire("Team created", "", "success").then(result => {
        if (result.value) {
          this.props.history.push({
            pathname: "/team/" + resp.id_team
          });
        }
      });
    });
  }
  saveChanges() {
    this.setState({ loading: true });
    actionAPI(this.props.id_token, "/team/update", {
      id_team: this.props.match.params.id,
      name: this.state.field_name.value
    }).then(resp => {
      this.setState({ loading: false });
      swal.fire(resp.message, "", "success").then(result => {
        if (result.value) {
          this.props.history.push({
            pathname: "/team/" + this.props.match.params.id
          });
        }
      });
    });
  }
  render() {
    return (
      <div className="Team main-holder">
        <Menu />
        <div className="content-holder">
          <div className={`wrapper ${this.state.loading ? "loading" : ""}`}>
            <Link to="/settings#teams" className="button-back">
              {"<"} Back to Teams
            </Link>
            <h2 className="title-page">
              {this.state.new_team ? "Create Team" : "Edit team"}
            </h2>
            {this.state.user_level < 2 && (
              <div>
                <SectionHeader
                  title="Team Identification"
                  description="Define team identification for easily cluster users."
                />
                <Container>
                  <div className="field">
                    <label>Name</label>
                    <Input
                      type="text"
                      name="name"
                      defaultValue={
                        this.state.user_data ? this.state.user_data.name : ""
                      }
                      placeholder="Insert team name"
                      validation={["required"]}
                      message="Please enter a team name"
                      setValue={val => this.setField(val, "name")}
                    />
                  </div>
                </Container>
                <div className="button-holder">
                  <div
                    className={`box-disable ${
                      this.state.field_name.valid ? "" : "disabled"
                    }`}
                  >
                    {this.state.new_team ? (
                      <button
                        className="main-button"
                        onClick={() => this.createTeam()}
                      >
                        Create Team
                      </button>
                    ) : (
                      <button
                        className="main-button"
                        onClick={() => this.saveChanges()}
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                  <span>or</span>
                  <Link to="/settings#teams">Cancel</Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user_level: state.user_level,
  id_token: state.id_token
});

export default withRouter(connect(mapStateToProps)(Team));
