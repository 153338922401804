import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./ServicesControl.css";

import actionAPI from "../../../actions/actionAPI";

class ServicesControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      blocked: props.blocked
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      status: nextProps.status,
      blocked: nextProps.blocked
    });
  }

  setEnv(action) {
    if (!this.state.blocked) {
      actionAPI(
        this.props.id_token,
        `/space/${this.props.idEnvironment}/${action?.toUpperCase()}`,
        null,
        "GET"
      ).then(() => {
        this.refresh();
      });
    }
  }

  refresh() {
    this.props.history.push("/refresh" + this.props.location.pathname);
  }

  render() {
    const { status, blocked } = this.state;
    return (
      <div
        className={`ServicesControl ${
          status === "Turning On All Services" ||
          status === "Turning Off All Services"
            ? "disable"
            : ""
        }`}
      >
        {status === "All Services Online" && (
          <button
            className={`down-button ${blocked ? "disabled" : ""}`}
            onClick={() => this.setEnv("STOP")}
          >
            Turn Off All Services
          </button>
        )}
        {status === "All Services Offline" && (
          <button
            className={`main-button  ${blocked ? "disabled" : ""}`}
            onClick={() => this.setEnv("START")}
          >
            Turn On All Services
          </button>
        )}

        <button
          className={`main-button  ${
            blocked || status !== "All Services Online" ? "disabled" : ""
          }`}
          onClick={() => this.setEnv("RESTART")}
        >
          Restart
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  id_token: state.id_token
});

export default withRouter(connect(mapStateToProps)(ServicesControl));
