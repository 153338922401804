import React, { Component } from "react";
import Autocomplete from "react-autocomplete";
import isEqual from "lodash/isEqual";
import "../AddField/AddField.css";

class AddTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      teams: props.teams,
      enabled: false,
      team_selected: {}
    };
  }
  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.teams, this.state.teams)) {
      this.setState({
        teams: nextProps.teams
      });
    }
  }
  setValue(value) {
    let val = this.state.teams.find(item => item.name === value);
    this.setState({
      value: value
    });
    if (val) {
      this.setState({
        enabled: true,
        team_selected: val
      });
    } else {
      this.setState({
        enabled: false
      });
    }
  }
  addTeam() {
    this.props.addTeam(this.state.team_selected);
  }
  render() {
    return (
      <div className="AddField">
        <label className="add-label">
          {this.props.description
            ? this.props.description
            : "Add teams to this user"}
        </label>
        <div className="box-field">
          <Autocomplete
            items={this.state.teams.map(item => ({ label: item.name }))}
            renderItem={(item, isHighlighted) => (
              <div
                className={`autocomplete-item ${
                  isHighlighted ? "highlighted" : ""
                }`}
              >
                {item.label}
              </div>
            )}
            getItemValue={item => item.label}
            value={this.state.value}
            onChange={e => this.setValue(e.target.value)}
            onSelect={value => this.setValue(value)}
            inputProps={{
              placeholder: "Search for teams by name"
            }}
          />
          <div
            className={`disable-button ${this.state.enabled ? "" : "disabled"}`}
          >
            <button onClick={() => this.addTeam()}>Add team</button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddTeam;
