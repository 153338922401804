import React, { Component } from "react";
import Autocomplete from "react-autocomplete";
import isEqual from "lodash/isEqual";
import "../AddField/AddField.css";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      users: props.users,
      enabled: false,
      user_selected: {}
    };
  }
  componentWillReceiveProps(nextProps){
    if (!isEqual(nextProps.users, this.state.users)) {
      this.setState({
        users: nextProps.users
      });
    }
  }
  setValue(value) {
    let val = this.state.users.find(item => item.nm_account === value);
    this.setState({
      value: value
    });
    if (val) {
      this.setState({
        enabled: true,
        user_selected: val
      });
    } else {
      this.setState({
        enabled: false
      });
    }
  }
  addUser(){
    this.props.addUser(this.state.user_selected)
  }
  render() {
    return (
      <div className="AddField">
        <label className="add-label">
        {this.props.description
            ? this.props.description
            : "Add users to this team"}
        </label>
        <div className="box-field">
          <Autocomplete
            items={this.state.users.map(item => ({ label: item.nm_account }))}
            renderItem={(item, isHighlighted) => (
              <div
                className={`autocomplete-item ${
                  isHighlighted ? "highlighted" : ""
                }`}
              >
                {item.label}
              </div>
            )}
            getItemValue={item => item.label}
            value={this.state.value}
            onChange={e => this.setValue(e.target.value)}
            onSelect={value => this.setValue(value)}
            inputProps={{
              placeholder: "Search for users by name"
            }}
          />
          <div
            className={`disable-button ${this.state.enabled ? "" : "disabled"}`}
          >
            <button onClick={() => this.addUser()}>Add user</button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddUser;