const validIPnumber = (num, max = 255) => {
  if (parseInt(num) <= max) {
    return true;
  }
  return false;
};

export const clean = stg => {
  return stg?.replace(/_/g, "");
};

export const validateForm = values => {
  let errors = {};
  const { ip_1, ip_2, ip_3, ip_4, ip_prefix } = values;
  const msg = "Insert a valid IP with a prefix";
  switch (true) {
    case !ip_1 || !validIPnumber(ip_1):
      errors.ip_1 = msg;
      break;
    case !ip_2 || !validIPnumber(ip_2):
      errors.ip_2 = msg;
      break;
    case !ip_3 || !validIPnumber(ip_3):
      errors.ip_3 = msg;
      break;
    case !ip_4 || !validIPnumber(ip_4):
      errors.ip_4 = msg;
      break;
    case !ip_prefix || !validIPnumber(ip_prefix):
      errors.ip_prefix = msg;
      break;
    default:
      errors = {};
      break;
  }
  return errors;
};

export const initialValue = {
  ip_1: "",
  ip_2: "",
  ip_3: "",
  ip_4: "",
  ip_prefix: "",
  description: ""
};

export const alert = {
  revoke: {
    title: "Are you sure ?",
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!"
  },
  submit: {
    title: "IP was successfully whitelisted.",
    type: "success"
  },
  errorSubimit: {}
};
