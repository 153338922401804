import React from "react";
import './SectionHeader.css';

const SectionHeader = props => (
  <div className="SectionHeader">
    <h3 className="section-title">{props.title}</h3>
    <span className="section-description">{props.description}</span>
  </div>
);

export default SectionHeader;
