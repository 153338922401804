import React from "react";

import Nav, {
  NavLogo,
  NavBottom
} from "semantix-ui/lib/Navigation/Nav";
import NavButton from "semantix-ui/lib/Buttons/NavButton";
import Logout from "semantix-ui/lib/Icons/Logout";

import IconLogo from "../../components/Menu/opengalaxy.svg";

const ServerError = ({auth, location}) => (
  <div className="main-holder">
    <Nav>
      <NavLogo url={IconLogo} />
      <NavBottom>
        <span
          onClick={() => {
            auth.logout()
          }}
        >
          <NavButton text="Logout">
            <Logout />
          </NavButton>
        </span>
      </NavBottom>
    </Nav>
    <div className="content-holder">
      <div className="wrapper">
        <div className="notfound">
          <img src="/static/spaceship.png" width="241" height="134" style={{top: '-40px', position: 'relative'}} />
          <div className="text-holder">
            <h2 className="title-page">
              Shoot! <br />
              <br /> Well, this is unexpected.
            </h2>
            <p>Error Code 500</p>
            <p>
              An error has occurred and we’re working to fix the problem.<br /> We’ll
              be up and running shortly. Is you need immediate help from our
              costumer service team, please ask using the chat.
            </p>
            <p>Thanks for your patience.</p>
            {console.group("Error detail")}
            {console.log(location.state)}
            {console.groupEnd()}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ServerError;
