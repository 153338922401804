import React, { useMemo } from "react";
import isEqual from "lodash/isEqual";
import { Link } from "react-router-dom";
import { useObservable } from "hooks";
import Table from "new-components/Table";
import Status from "new-components/Status";
import Service from "new-components/Service/Service";
import Container from "new-components/Box/Container";
import ServicesControl from "../ServicesControl";
import * as utils from "./utils";

export default function Details(props) {
  const link = useObservable(props.link);
  const stage = useObservable(props.stage);
  const blockedAt = useObservable(props.blocked);
  const services = useObservable(props.services);
  const stopTime = useObservable(props.stop_time);
  const userLevel = useObservable(props.userLevel);
  const frequency = useObservable(props.frequency);
  const statusBar = useObservable(props.status_bar);
  const startTime = useObservable(props.start_time);
  const consulting = useObservable(props.consulting);
  const deleteDate = useObservable(props.delete_date);
  const idSpace = useObservable(props.idEnvironment);
  const messageUp = useObservable(props.msg_update);
  const statusName = useObservable(props.status_name);
  const status = useObservable(props.status);

  const MapServices = useMemo(
    () =>
      services?.map((item, index) => (
        <Service
          data={{ ...item, statusName }}
          key={index}
          index={index}
          userlevel={userLevel}
        />
      )) || <React.Fragment></React.Fragment>,
    [services]
  );

  return (
    <div>
      <Status
        status={statusName}
        delete_date={deleteDate}
        status_bar={statusBar}
        msg_update={messageUp}
      >
        {stage !== "Enterprise" ? (
          <ServicesControl
            idEnvironment={idSpace}
            status={statusName}
            blocked={blockedAt}
          />
        ) : (
          ""
        )}
      </Status>
      <div className="header-services">
        <h3 className="title">Services</h3>
        {!!link && parseInt(status) === 1 ? (
          <div className="right-buttons">
            {stage !== "Enterprise" ? (
              <a
                href={blockedAt ? "javascript:void(0)" : link}
                className={`main-button ${blockedAt ? "disabled" : ""}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open Monitoring
              </a>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <Container cssClass="TableServices">
        <Table
          header={[
            ["Name", 40],
            ["Paths", 60],
          ]}
        >
          {MapServices}
        </Table>
      </Container>
      <Container cssClass="DetailsUptime">
        <h3 className="title">Services Uptime</h3>

        {stage !== "Enterprise" && !!frequency ? (
          <p className="text">
            This Space is automatically up on <strong>{frequency}</strong> from{" "}
            <strong>{startTime}</strong> to{" "}
            <strong>{stopTime}</strong>.
          </p>
        ) : (
          <p className="text">
            This Space is automatically up{" "}
            <strong>24 hours a day, 7 days a week.</strong>
          </p>
        )}
      </Container>
    </div>
  );
}
