import axios from "axios";

const actionCreateUserAuth = (email, password) => {
  return axios
    .post(`https://${process.env.REACT_APP_AUTH_DOMAIN}/oauth/token`, {
      grant_type: "client_credentials",
      client_id: process.env.REACT_APP_AUTH_CLIENT,
      client_secret: process.env.REACT_APP_AUTH_SECRET,
      audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
    })
    .then((resp) => {
      return axios({
        url: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/users`,
        method: "post",
        headers: { Authorization: `Bearer ${resp.data.access_token}` },
        data: {
          connection: "Username-Password-Authentication",
          email: email,
          password: password,
          user_metadata: {},
          email_verified: false,
          app_metadata: {},
        },
      });
    });
};

export default actionCreateUserAuth;
