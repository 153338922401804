import React from "react";
import { useObservable } from "hooks";/* 
import IconArrow from "new-components/Icons/Arrow"; */
import "./SettingsMenu.css";

export default function SettingsMenu(props) {
  const tab = useObservable(props.section);

  return (
    <div className="SettingsMenu">
      <button
        className={`menu-button ${tab.includes("users") ? "active" : ""}`}
        onClick={() => props.setSection("users")}
      >
        Users
      </button>
      {/* <button
        className={`menu-button ${tab.includes("teams") ? "active" : ""}`}
        onClick={() => props.setSection("teams")}
      >
        Teams
      </button>
      <div className="menu-dropdown">
        <button
          className={`menu-button ${tab.includes("billing-") ? "active" : ""}`}
        >
          Billing
        </button>
        <div className="menu-submenu">
          <button
            className="submenu-button"
            onClick={() => props.setSection("billing-information")}
          >
            Billing Information
          </button>
          <button
            className="submenu-button"
            onClick={() => props.setSection("billing-review")}
          >
            Billing Review
          </button>
        </div>
      </div> */}
    </div>
  );
}
