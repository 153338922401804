import React from "react";

import Nav, {
  NavLogo,
  NavBottom
} from "semantix-ui/lib/Navigation/Nav";
import NavButton from "semantix-ui/lib/Buttons/NavButton";
import Logout from "semantix-ui/lib/Icons/Logout";

import IconLogo from "../../components/Menu/opengalaxy.svg";

const VerifyEmail = ({auth}) => (
  <div className="main-holder">
    <Nav>
      <NavLogo url={IconLogo} />
      <NavBottom>
        <span
          onClick={() => {
            auth.logout()
          }}
        >
          <NavButton text="Logout">
            <Logout />
          </NavButton>
        </span>
      </NavBottom>
    </Nav>  
    <div className="content-holder">
      <div className="wrapper">
        <h2 className="title-page">Almost there...</h2>
        <p>Please verify your email to activate your account.</p>
      </div>
    </div>
  </div>
);

export default VerifyEmail;
