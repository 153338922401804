import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TabButtons.css';

class TabButtons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 0
    }
  }
  componentWillReceiveProps(nextProps){
    this.props.data.forEach((item, index) => {
      if(item === nextProps.selectedItem){
        this.setState({
          selected: index
        })
      }
    })
  }
  handleClick(value, index) {
    this.setState({
      selected: index
    })
    this.props.onClick(value)
  }
  render() {
    return (
      <div className="TabButtons">
        {this.props.data.map((item, index) => (
          <button
            key={item}
            className={this.state.selected === index ? 'selected' : ''}
            onClick={() => {
              this.handleClick(item, index)
            }}
          >
            {item}
          </button>
        ))}
      </div>
    )
  }
}

TabButtons.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  selectedItem: PropTypes.string
}

TabButtons.defaultProps = {
  data: [],
  onClick: () => false,
  selectedItem: ''
}

export default TabButtons
