import React, { Component } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import IconLogo from "./opengalaxy.svg";
import Space from "./Icons/Space";
import Plugins from "./Icons/Plugins";
import Settings from "semantix-ui/lib/Icons/Settings";
import Logout from "semantix-ui/lib/Icons/Logout";

import Nav, {
  NavLogo,
  NavBottom,
  NavContent
} from "semantix-ui/lib/Navigation/Nav";
import NavButton from "semantix-ui/lib/Buttons/NavButton";

// SERVICES
import auth from "../../Auth/Auth";

// STYLE
import "./Menu.css";

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_level: props.user_level
    };
  }
  logout() {
    auth.logout();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      user_level: nextProps.user_level
    });
  }

  render() {
    return (
      <Nav>
        <NavLogo url={IconLogo} />
        <NavContent>
          <NavLink to="/environments">
            <NavButton text="Spaces">
              <Space />
            </NavButton>
          </NavLink>
{/*           {this.state.user_level <= 2 && (
            <NavLink to="/plugins">
              <NavButton text="Plugins">
                <Plugins />
              </NavButton>
            </NavLink>
          )} */}
        </NavContent>
        <NavBottom>
          {this.state.user_level <= 2 && (
            <NavLink to="/settings">
              <NavButton text="Settings">
                <Settings />
              </NavButton>
            </NavLink>
          )}
          <span
            onClick={() => {
              this.logout();
            }}
          >
            <NavButton text="Logout">
              <Logout />
            </NavButton>
          </span>
        </NavBottom>
      </Nav>
    );
  }
}

const mapStateToProps = state => ({
  user_level: state.user_level
});

export default withRouter(connect(mapStateToProps)(Menu));
