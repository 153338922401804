import React, {
  useMemo,
  useEffect,
  /*   useRef, */
  useState,
  useCallback
} from "react";
/* import Moment from "react-moment"; */
import "moment-timezone";
import { useDispatch } from "react-redux";
import clipboard from "clipboard";
import { TableCol, TableRow } from "new-components/Table";
import IconClipboard from "new-components/Icons/Clipboard";
import Robustness from "new-components/Service/Robustness";
import Applications from "new-components/Service/Applications";
/* import actionAPI from "actions/actionAPI"; */
import { useObservable } from "hooks";
import "./Service.css";

export default function Service(props) {
  /*   const serviceRef = useRef(null); */
  const dispatch = useDispatch();
  const service = useObservable(props.data);
  const data = useDispatch(props.data);
  const envService = useObservable(props.data?.id_spc_link);
  const applications = useObservable(props.data?.applications);
  const userLevel = useObservable(props.userLevel);
  const { ds_service, status, ds_link } = service;
  // const [open, setOpen] = useState(false);
  const [robustness, setRobustness] = useState({});
  const [applicationList, setApplicationList] = useState(applications);

  // console.log(props);

  useEffect(() => {
    (() => {
      return new clipboard(".copy");
    })();
  }, []);

  const handleRobustness = useCallback(
    config => {
      setRobustness(config);
      dispatch({
        type: "ADD_ADDED_SERVICES",
        data: {
          ...service,
          id_envservice: envService,
          robustness: { ...robustness }
        }
      });
    },
    [robustness]
  );

  const handleApplications = (action, item) => {
    setApplicationList(prev =>
      action
        ? [...prev, item]
        : prev?.filter(i => i.id_application !== item.id_application)
    );
  };

  const firstCol = useMemo(
    () => (
      <TableCol size={40}>
        <div className={`service-data ${status}`}>
          <div className="service-text">
            <span className="name">
              <img
                className="service-logo"
                src={`https://s3.amazonaws.com/manager-interface/${ds_service?.toLocaleLowerCase()}.png`}
                alt={service.ds_service}
              />
              {service.ds_service}
            </span>
          </div>
        </div>
      </TableCol>
    ),
    [ds_service, status]
  );

  const secondCol = useMemo(
    () => (
      <TableCol size={60}>
        <div className="bts-path">
          {ds_service?.includes("ElasticSearch" || "Logstash") ? (
            <div className="copy-path">
              <span>{ds_link}</span>
              <button className="copy" data-clipboard-text={ds_link}>
                <IconClipboard />
              </button>
            </div>
          ) : (
            <div className="copy-path">
              <a href={ds_link} target="_blank">
                {ds_link}
              </a>
              <button className="copy" data-clipboard-text={ds_link}>
                <IconClipboard />
              </button>
            </div>
          )}
        </div>
      </TableCol>
    ),
    [ds_link, ds_service]
  );

  return (
    <div className="Service NewService">
      <TableRow>
        {firstCol}
        {secondCol}
      </TableRow>
      <div className="ServiceConfig" /* ref={serviceRef} */>
        <div className="over-holder">
          <div className="col">
            <Robustness
              setRobustness={config => handleRobustness(config)}
              data={robustness}
            />
          </div>
          <div className="col">
            <Applications
              onClick={(action, item) => handleApplications(action, item)}
              data={applicationList}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
