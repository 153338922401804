const added_services = (state = [], action) => {
  switch(action.type) {
    case 'UPDATE_ADDED_SERVICES':
      return action.data
    case 'ADD_ADDED_SERVICES':
      let temp = [...state];
      temp[action.index] = action.data;
      return temp
    case 'REMOVE_ADDED_SERVICES':
      return state.filter(
        (item, idx) => action.index !== idx
      )
    default:
      return state
  }
}

export default added_services