import React, { useState, useMemo } from "react";
import Autocomplete from "react-autocomplete";
import { useObservable } from "hooks";
import "./AddField.css";

export default function AddField(props) {
  const spaces = useObservable(props.environments);
  const label = useObservable(props.label);
  const [enable, setEnable] = useState(false);
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState({});

  const handleSetValue = value => {
    const val = spaces.find(i => i.nm_space === value);
    setValue(val?.nm_space || value);
    if (val) {
      setSelected(val);
      setEnable(true);
      return void 0;
    }
    setEnable(false);
  };

  const handleAddSpace = () => {
    props.addSpace(selected);
  };

  const autoComplete = useMemo(
    () => (
      <Autocomplete
        getItemValue={item => item.label}
        items={spaces.map(item => ({
          label: item.nm_space
        }))}
        renderItem={(item, isHighlighted) => (
          <div
            className={`autocomplete-item ${
              isHighlighted ? "highlighted" : ""
            }`}
            key={item.label}
          >
            {item.label}
          </div>
        )}
        value={value}
        onChange={e => handleSetValue(e.target.value)}
        onSelect={value => handleSetValue(value)}
        inputProps={{
          placeholder: "Search for spaces by name"
        }}
      />
    ),
    [spaces, value]
  );

  return (
    <div className="AddField">
      <label className="add-label">{label}</label>
      <div className="box-field">
        {autoComplete}
        <div className={`disable-button ${enable ? "" : "disabled"}`}>
          <button onClick={() => handleAddSpace()}>Add space</button>
        </div>
      </div>
    </div>
  );
}
