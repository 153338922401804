import React, { Component } from "react";
import Menu from "../../components/Menu/Menu";
import axios from "axios";

class CreateCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };
  }
  onChange(e) {
    this.setState({
      name: e.target.value
    });
  }
  create() {
    const tokenStr = localStorage.getItem("id_token");
    if (tokenStr) {
      axios({
        url: "https://dev-api.semantixmanager.net/company/create",
        method: "post",
        headers: { Authorization: `Bearer ${tokenStr}` },
        data: {
          name: "Semantix",
          id_region: 1
        }
      })
        .then(resp => {
          return resp.data;
        })
        .then(data => {
          return axios({
            url: "https://dev-api.semantixmanager.net/account/create",
            method: "post",
            headers: { Authorization: `Bearer ${tokenStr}` },
            data: {
              name: this.props.auth.userProfile.email.split("@")[0],
              email: this.props.auth.userProfile.email,
              id_company: data.id_company,
              id_role: 1
            }
          });
        })
    }
  }
  render() {
    return (
      <div className="main-holder">
        <Menu />
        <div className="content-holder">
          <div className="wrapper">
            <h2 className="title-page">Create company</h2>
            <div><label>Name:</label></div>
            <input
              type="text"
              value={this.state.name}
              onChange={this.onChange.bind(this)}
            />
            <button onClick={this.create.bind(this)}>Create</button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateCompany;
