import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useObservable } from "hooks";
import Menu from "components/Menu/Menu";
import SettingsMenu from "new-components/Settings/SettingsMenu";
import AllUsers from "new-components/Settings/AllUsers";
// import AllTeams from "new-components/Settings/AllTeams";
// import BillingInformation from "new-components/Settings/BillingInformation";
// import BillingReview from "new-components/Settings/BillingReview";
import actionAPI from "actions/actionAPI";
import { set } from "lodash";

export default function Settings(props) {
  const dispatch = useDispatch();
  const store = useSelector((s) => s);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("users");
  const userLevel = useObservable(store?.user_level);
  const idToken = useObservable(store?.id_token);
  const users = useObservable(store?.users);

  const get = async () => actionAPI(idToken, "/company/accounts", null, "GET");

  useEffect(() => {
    (async () => {
      const response = await get();
      if (response) {
        dispatch({
          type: "ADD_USERS",
          data: response,
        });
        setLoading(false);
      }
    })();
    return () => setLoading(true);
  }, [idToken]);

  const TabViews = useMemo(() => {
    setLoading(false);
    switch (tab) {
      case "teams":
        return <div>TEAMS</div>;
      case "billing-information":
        return <div>billing</div>;
      default:
        return <AllUsers data={users} userLevel={userLevel} />;
    }
  }, [tab, users]);

  return (
    <div className="Settings main-holder">
      <Menu />
      <div className="content-holder">
        <div className="wrapper">
          <h2 className="title-page">Settings</h2>

          <div>
            <SettingsMenu section={tab} setSection={(sec) => setTab(sec)} />
            {TabViews}
          </div>
        </div>
      </div>
    </div>
  );
}
