import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import Menu from "../../components/Menu/Menu";
import SectionHeader from "../../new-components/SectionHeader";

import Table, { TableCol, TableRow } from "../../new-components/Table";

import actionAPI from "../../actions/actionAPI";

import "./ServicesUptime.css";

const days = [
  "12:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM"
];

class ServicesUptime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      from: days[0],
      to: [...days.filter(item => item !== days[0])],
      toSelect: days[1],
      week_days: [
        { label: "Monday", id: 1, select: false },
        { label: "Tuesday", id: 2, select: false },
        { label: "Wednesday", id: 3, select: false },
        { label: "Thursday", id: 4, select: false },
        { label: "Friday", id: 5, select: false },
        { label: "Saturday", id: 6, select: false },
        { label: "Sunday", id: 7, select: false }
      ],
      current_cost: 0,
      compute_feature: "",
      compute_value: 0,
      hours_per_month: 0,
      storage_feature: "",
      storage_quantity: "",
      storage_value: 0,
      monthly_cost: 0,
      billing_day: 0,
      estimated_cost: 0,
      billing_month: "",
      terms_agree: false,
      credit_card: props.credit_card,
      schedule: ""
    };
  }
  componentDidMount() {
    let locState = this.props.location.state;
    let temp = [...this.state.week_days];
    locState.frequency.forEach(item => {
      temp.find(it => it.label === item).select = true;
    });
    this.setState(
      {
        week_days: temp,
        from: locState.start_time,
        to: days.slice(days.indexOf(locState.start_time)),
        toSelect: locState.stop_time
      },
      () => this.update(true)
    );
  }
  onChangeFrom(val) {
    this.setState(
      {
        to: days.slice(days.indexOf(val) + 1),
        from: val,
        toSelect: days.slice(days.indexOf(val) + 1)[0]
      },
      () => this.update()
    );
  }
  onChangeTo(val) {
    this.setState(
      {
        toSelect: val
      },
      () => this.update()
    );
  }
  onSelectDay(label) {
    let temp = [...this.state.week_days];
    let el = temp.find(it => it.label === label);
    el.select = !el.select;
    this.setState(
      {
        week_days: temp
      },
      () => this.update()
    );
  }
  update(first) {
    this.setState({
      loading: true
    });
    actionAPI(this.props.id_token, "/cost/prevision", {
      id_environment: this.props.match.params.id,
      frequency: this.state.week_days
        .filter(item => item.select)
        .map(item => item.id)
        .join(","),
      start_time: this.state.from,
      stop_time: this.state.toSelect
    })
      .then(resp => {
        this.setState({
          compute_feature: resp.compute_feature,
          compute_value: resp.compute_value,
          hours_per_month: resp.hours_per_month,
          storage_feature: resp.storage_feature,
          storage_quantity: resp.storage_quantity,
          storage_value: resp.storage_value,
          monthly_cost: resp.monthly_cost,
          billing_day: resp.billing_day,
          billing_month: resp.billing_month,
          estimated_cost: resp.estimated_cost,
          loading: false
        });
        if (first) {
          this.setState({
            current_cost: resp.monthly_cost
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Something went wrong!"
        });
      });
  }
  updateStartStop() {
    if (!!this.state.schedule) {
      this.setState({
        loading: true
      });
      actionAPI(this.props.id_token, "/environment/update_start_stop", {
        id_environment: this.props.match.params.id,
        frequency: this.state.week_days
          .filter(item => item.select)
          .map(item => item.id)
          .join(","),
        start_time: this.state.from,
        stop_time: this.state.toSelect,
        update_now: this.state.schedule === "turn-off" ? true : false
      })
        .then(resp => {
          this.setState({
            loading: false
          });

          Swal.fire({
            title: "Changes were requested successfully.",
            text:
              this.state.schedule === "turn-off"
                ? "Turning Off All Services to update."
                : "Space will be updated next time Space turns Off.",
            type: "success",
            confirmButtonText: "Go to Space"
          }).then(result => {
            if (result.value) {
              this.props.history.push({
                pathname: "/environment/" + this.props.match.params.id
              });
            }
          });
        })
        .catch(err => {
          this.setState({
            loading: false
          });
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "Something went wrong!"
          });
        });
    }
  }
  activateSpace() {
    if (this.state.terms_agree & this.state.credit_card) {
      this.setState({
        loading: true
      });
      actionAPI(this.props.id_token, "/environment/activate", {
        id_environment: this.props.match.params.id,
        frequency: this.state.week_days
          .filter(item => item.select)
          .map(item => item.id)
          .join(","),
        start_time: this.state.from,
        stop_time: this.state.toSelect
      })
        .then(resp => {
          this.setState({
            loading: false
          });
          Swal.fire({
            title: "Space was successfully activated.",
            type: "success",
            confirmButtonText: "Go to Space"
          }).then(result => {
            if (result.value) {
              this.props.history.push({
                pathname: "/environment/" + this.props.match.params.id
              });
            }
          });
        })
        .catch(err => {
          this.setState({
            loading: false
          });
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: "Something went wrong!"
          });
        });
    }
  }

  selectSchedule(field) {
    this.setState({
      schedule: field
    });
  }

  render() {
    const {
      to,
      from,
      toSelect,
      week_days,
      compute_feature,
      compute_value,
      hours_per_month,
      storage_feature,
      storage_quantity,
      storage_value,
      monthly_cost,
      billing_day,
      billing_month,
      current_cost,
      estimated_cost,
      credit_card
    } = this.state;
    const { location } = this.props;
    return (
      <div className="ServicesUptime main-holder">
        <Menu />
        <div className="content-holder">
          <div className={`wrapper ${this.state.loading ? "loading" : ""}`}>
            {location.state.activateSpace ? (
              <React.Fragment>
                <h3 className="title-page">Activate Space</h3>
                {!credit_card && (
                  <div className="MessagePurchase">
                    <span>
                      To Activate a Space, you have to
                      <Link to="/add-credit-card">Add a Credit Card</Link>.
                    </span>
                  </div>
                )}

                <p className="its-not">
                  It’s not possible to change this Space’s Pack and Robustness.
                </p>
                <SectionHeader
                  title="Services Uptime"
                  description="Define the Space Services Uptime during the week"
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Link
                  to={`/environment/${this.props.match.params.id}`}
                  className="link-back"
                >
                  {"< Back to Services"}
                </Link>
                <h3 className="title-page">Space’s Services Uptime</h3>
                <SectionHeader
                  title="Services Uptime"
                  description="Change Services automatic Uptime according to your necessity. These changes may affect your monthly billing."
                />
              </React.Fragment>
            )}

            <div className="day-select">
              {week_days.map(item => (
                <div className="day" key={item.label}>
                  <input
                    type="checkbox"
                    id={item.label}
                    checked={item.select}
                    onChange={e => {
                      this.onSelectDay(item.label);
                    }}
                  />
                  <label htmlFor={item.label}>{item.label}</label>
                </div>
              ))}
            </div>
            <div className="schedule-container">
              <div className="schedule">
                <label htmlFor="from">From</label>
                <select
                  id="from"
                  onChange={e => this.onChangeFrom(e.target.value)}
                >
                  {days
                    .filter(it => it !== days[days.length - 1])
                    .map((item, index) => (
                      <option
                        key={index}
                        value={item}
                        selected={item === from ? true : false}
                      >
                        {item}
                      </option>
                    ))}
                </select>
              </div>
              <div className="schedule">
                <label htmlFor="to">To</label>
                <select id="to" onChange={e => this.onChangeTo(e.target.value)}>
                  {to.map((item, index) => (
                    <option
                      key={index}
                      value={item}
                      selected={item === toSelect ? true : false}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <span className="time-estimated">
                {hours_per_month} estimated hours per month
              </span>
            </div>
            <SectionHeader
              title="Pricing"
              description="Review your finantial costs."
            />
            <Table header={[["Feature", 40], ["Quantity", 30], ["Price", 30]]}>
              <TableRow>
                <TableCol size={40}>{compute_feature}</TableCol>
                <TableCol size={30}>{hours_per_month} hour per month</TableCol>
                <TableCol size={30}>
                  {compute_value.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "BRL"
                  })}
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol size={40}>{storage_feature}</TableCol>
                <TableCol size={30}>{storage_quantity}</TableCol>
                <TableCol size={30}>
                  {storage_value.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "BRL"
                  })}
                </TableCol>
              </TableRow>
              <TableRow cssClass="total">
                <TableCol size={100}>
                  <div className="row-total">
                    <div className="text-total">
                      <strong>Estimated monthly billing</strong>
                      <span>
                        {location.state.activateSpace ? (
                          <React.Fragment>
                            Calculated based on {hours_per_month} hours per
                            month <br />
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        {credit_card ? (
                          <React.Fragment>
                            Corrected Billing to be charged on {billing_month}{" "}
                            {billing_day}th.
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <strong className="price">
                      {monthly_cost.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "BRL"
                      })}
                    </strong>
                  </div>

                  {(current_cost !== monthly_cost) &
                  !location.state.activateSpace ? (
                    <div className="row-total">
                      <span>Current setting price is </span>
                      <span>
                        {current_cost.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "BRL"
                        })}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {location.state.activateSpace ? (
                    <div className="row-total">
                      <div className="text-total">
                        <strong>Pay now</strong>
                      </div>
                      <span className="info">
                        Covers from now until next billing date
                      </span>
                      <strong className="price-now">
                        {estimated_cost.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "BRL"
                        })}
                      </strong>
                    </div>
                  ) : (
                    ""
                  )}
                </TableCol>
              </TableRow>
            </Table>
            {location.state.activateSpace ? (
              <React.Fragment>
                <div className="agreeCheckbox">
                  <input
                    type="checkbox"
                    id="agree"
                    onChange={el => {
                      this.setState({ terms_agree: el.target.checked });
                    }}
                  />
                  <label htmlFor="agree">I agree with the review and the</label>{" "}
                  <a href="/terms-of-service" target="_blank">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="/privacy-policy" target="_blank">
                    Privacy policy
                  </a>
                  .
                </div>
                <div className="button-holder">
                  <div className="button-holder">
                    <button
                      className={`main-button ${
                        this.state.terms_agree & credit_card ? "" : "disabled"
                      }`}
                      onClick={() => {
                        this.activateSpace();
                      }}
                    >
                      Activate Space
                    </button>
                    <span>or</span>
                    <Link to={`/environment/${this.props.match.params.id}`}>
                      Cancel
                    </Link>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <SectionHeader
                  title="Schedule Update"
                  description="Spaces has to be Offlie to update"
                />
                <div className="ScheduleUpdate">
                  <div className="select-schedule">
                    <input
                      type="radio"
                      id="turn-off"
                      name="schedule"
                      onChange={() => this.selectSchedule("turn-off")}
                    />
                    <label htmlFor="turn-off">
                      Turn Off All Services now to update.
                    </label>
                  </div>
                  <div className="select-schedule">
                    <input
                      type="radio"
                      id="turn-offline"
                      name="schedule"
                      onChange={() => this.selectSchedule("turn-offline")}
                    />
                    <label htmlFor="turn-offline">
                      Update next time All Services turn Offline.
                    </label>
                  </div>
                </div>
                <div className="button-holder">
                  <button
                    className={`main-button ${
                      !!this.state.schedule ? "" : "disabled"
                    }`}
                    onClick={() => {
                      this.updateStartStop();
                    }}
                  >
                    Change Services Uptime
                  </button>
                  <span>or</span>
                  <Link to={`/environment/${this.props.match.params.id}`}>
                    Cancel
                  </Link>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  id_token: state.id_token,
  credit_card: state.credit_card
});

export default connect(mapStateToProps)(ServicesUptime);
