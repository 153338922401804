import React, { Component } from "react";
import { connect } from "react-redux";
import TabButtons from "../../TabButtons";
import "./Robustness.css";

class Robustness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      robustness: props.robustness,
      robSelected: {}
    };
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      robustness: nextProps.robustness
    })
  }
  componentDidMount() {
    if (this.props.data) {
      this.setState({
        robSelected: this.props.data
      });
      this.props.setRobustness(this.props.data);
    } else {
      let temp = {};
      this.state.robustness.forEach(item => {
        temp[item.group] = item.values[0];
      });
      this.setState({
        robSelected: temp
      });

      this.props.setRobustness(temp);
    }
  }
  handleTabClick(item, value) {
    this.setState(
      {
        robSelected: {
          ...this.state.robSelected,
          [item]: value
        }
      },
      () => {
        this.props.setRobustness(this.state.robSelected);
      }
    );
  }
  render() {
    return (
      <div className="Robustness">
        {this.state.robustness.map(item => (
          <div className={`robustness-line ${item.name === 'Data Volume' ? 'disabled' : ''}`} key={item.id_robustness}>
            <label className="robustness-label">{item.name}</label>
            <TabButtons
              data={item.values}
              onClick={value => this.handleTabClick(item.group, value)}
              selectedItem={this.state.robSelected[item.group]}
            />
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  robustness: state.robustness
});

export default connect(mapStateToProps)(Robustness);
