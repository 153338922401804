import React from "react";
import "./PrivacyPolicy.css";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => (
  <div className="main-holder">
    <div className="content-holder">
      <div className="wrapper privacy-policy">
        <h2 className="title-page">Política de Privacidade</h2>
        <p>
          Esta Política de Privacidade tem como objetivo descrever como as suas
          informações e dados (“Informações Pessoais”) serão coletados, usados,
          compartilhados e armazenados por meio da plataforma OpenGalaxy. Mesmo
          sendo parte integrante dos Termos e Condições Gerais de Uso, a
          Política não se aplica ao Seu Conteúdo, conforme a Cláusula 6.3 dos
          Termos.
        </p>
        <p>
          Ao se cadastrar em nossa plataforma para utilizar os Serviços, você
          automaticamente aceita a nossa Política. Isso demonstra que você está
          ciente e de acordo com a forma como utilizaremos as Informações
          Pessoais. Nós recomendamos que você leia atentamente a totalidade
          desta Política. Caso não concorde com a nossa Política, por favor, não
          continue com o registro de sua conta e não utilize os nossos Serviços.
          O seu consentimento para tratamento dos seus dados conforme descrito
          nesta Política é condição indispensável para a utilização dos
          Serviços.
        </p>
        <p>
          A Política de Privacidade tem por finalidade prestar as seguintes
          informações:
        </p>
        <ol>
          <li>quais Informações Pessoais são coletadas por nós;</li>
          <li>como nós usamos as Informações Pessoais;</li>
          <li>com quem nós compartilhamos as Informações Pessoais;</li>
          <li>por qual prazo as Informações Pessoais são armazenadas;</li>
          <li>
            como você pode controlar a coleta e o armazenamento de suas
            Informações Pessoais;
          </li>
          <li>quando a Política de Privacidade pode ser alterada;</li>
          <li>quais são os padrões de segurança em relação à plataforma;</li>
          <li>
            onde encaminhar as dúvidas sobre a o tratamento das Informações
            Pessoais
          </li>
        </ol>
        <p>
          Se você tiver qualquer dúvida ou demanda sobre esta Política e/ou
          sobre o tratamento das suas Informações Pessoais, por favor entre em
          contato conosco:
        </p>
        <p>
          <strong>SEMANTIX TECNOLOGIA EM SISTEMA DE INFORMAÇÃO S.A.</strong>
          <br />
          Av. Eusébio Matoso, 1375 - Pinheiros - São Paulo
          <br />
          CNPJ/MF 09.162.524/0001-53
          <br />
          e-mail:{" "}
          <a href="mailto:opengalaxy@opengalaxy.io">opengalaxy@opengalaxy.io</a>
        </p>

        <h3>1. INFORMAÇÕES QUE COLETAMOS</h3>
        <p>
          <strong>A. Informações Fornecidas Voluntariamente Por Você</strong>
        </p>
        <p>
          <strong>Registro de Conta.</strong> Ao efetuar o registro da sua conta
          em nossa plataforma, nós coletamos informações de identificação
          pessoal que incluem: seu nome, nome da sua empresa, seu cargo na
          empresa, seu endereço, seu e-mail e seu número de telefone.
        </p>
        <p>
          <strong>Informações de Pagamento.</strong> Ao adicionar suas
          informações de pagamento na sua conta, essas informações serão
          direcionadas ao Terceiro responsável pelo processamento do pagamento
          dos Serviços. Nós não armazenaremos suas informações de pagamento.
          Entretanto, nós poderemos, acessar tais informações por meio do
          Terceiro responsável pelo processamento do pagamento.
        </p>
        <p>
          <strong>Comunicações.</strong> Se você nos contatar diretamente, por
          meio dos canais adequados, nós poderemos receber informações como: o
          conteúdo da mensagem, os anexos que você nos enviar e quaisquer outras
          informações que você nos forneça.
        </p>

        <p>
          <strong>
            B. Informações Que Coletamos Quando Você Utiliza Os Serviços.
          </strong>
        </p>
        <p>
          <strong>Cookies e Demais Tecnologias de Rastreamento.</strong> Quando
          você visita nosso site, um ‘cookie’ é inserido no seu navegador por
          meio dos softwares de altimetria do sistema. A partir desse ‘cookie’
          serão coletadas informações como sua localização geográfica, fonte de
          referência, o sistema operacional e browser utilizados, tipo de
          hardware, endereço MAC (media access control), IMEI (international
          mobile equipment identity) e UDID (identifier number, utilizado em
          dispositivos fabricados pela Apple Inc.) e endereço de protocolo de
          Internet (IP). Nós também poderemos coletar automaticamente
          informações sobre a maneira que você utiliza os nossos Serviços, sobre
          a funcionalidades dos Serviços, frequência de visitas e demais
          informações relacionadas com as suas interações com o Serviços.
        </p>
        <p>
          <strong>Utilização dos Serviços.</strong> Ao utilizar os Serviços, nós
          poderemos coletar informações sobre o seu engajamento e sua
          utilização, tais como uso de memória e de processamento, capacidade de
          armazenamento, navegação na plataforma e altimetria do sistema. Nós
          utilizamos esses dados para operacionalizar, manter e melhorar a
          performance dos Serviços, bem como desenvolver novas funcionalidades,
          garantir a segurança e fornecer suporte a você.
        </p>
        <p>
          <strong>C. Informações Que Recebemos de Terceiros</strong>
        </p>
        <p>
          <strong>Contas em serviços de Terceiros.</strong> Se você escolher
          utilizar os nossos Serviços por meio de uma conta registrada em algum
          serviço de Terceiro, como o Facebook, nós receberemos as informações
          relativas a esta conta para que a conexão seja autorizada.
        </p>
        <p>
          <strong>>Terceiros Parceiros.</strong> Nós poderemos receber
          informações disponíveis publicamente, ou que você tenha autorizado a
          divulgação, de Terceiros que sejam nossos Parceiros e integrá-los com
          os dados que temos sobre você.
        </p>
        <p>
          Sempre que pedirmos suas informações pessoais, apresentaremos a razão
          e o motivo para tais pedidos.
        </p>

        <h3>2. USO DOS SEUS DADOS</h3>
        <p>
          Todos os dados que coletamos são utilizados para o fornecimento dos
          Serviços e somente os usaremos para os fins aqui descritos e
          autorizados por você, principalmente para que você possa utilizar os
          Serviços de forma plena. Desta forma, poderemos personalizar os
          Serviços para que estes se tornem cada vez mais relevantes. Ademais,
          seus dados também podem ser utilizados para criar novos serviços,
          produtos e funcionalidades.
        </p>
        <p>
          Eventualmente, poderemos utilizar dados para finalidades não previstas
          nesta Política de Privacidade. Nessa hipótese, esse uso somente será
          feito mediante seu consentimento.
        </p>
        <p>
          Seu e-mail será utilizado para envio de material ou informação que
          você eventualmente requerer. Também pode ser usado para envio de
          Newsletters, sempre relacionadas ao tema dos Serviços. O e-mail será
          utilizado ainda para comunicar o lançamento de novos materiais ou de
          novos produtos, nossos e de parceiros. No entanto, você pode cancelar
          a assinatura a qualquer momento.
        </p>

        <h3>3. COMO COMPARTILHAMOS AS INFORMAÇÕES</h3>
        <p>
          Para melhor oferecimento dos Serviços, nós compartilharemos as
          Informações Pessoais da seguinte forma:
        </p>
        <ul>
          <li>
            <strong>Serviço de hospedagem.</strong> A Plataforma e os Serviços
            encontram-se hospedados em servidores de titularidade das empresas
            Amazon Web Services. A política de privacidade, bem como os termos
            de uso do serviço de hospedagem oferecido podem ser acessados no
            site{" "}
            <a href="https://aws.amazon.com/pt/legal/?nc1=f_cc">
              https://aws.amazon.com/pt/legal/?nc1=f_cc
            </a>
          </li>
          <li>
            <strong>Terceiros Parceiros.</strong> Os Serviços oferecidos são uma
            combinação de produtos dos Terceiros Parceiros e o uso desses
            produtos está sujeito às Políticas de Privacidade deles. Para mais
            informações, visite{" "}
            <a href="https://www.cloudera.com/products/open-source.html">
              https://www.cloudera.com/products/open-source.html
            </a>{" "}
            (Cloudera) ou{" "}
            <a href="https://www.elastic.co/gdpr">
              https://www.elastic.co/gdpr
            </a>{" "}
            (Elastic.co).
          </li>
          <li>
            <strong>Empresas de processamento de meios de pagamento.</strong>{" "}
            Todos os pagamentos realizados são processados através de
            ferramentas de titularidade da empresa MOIP. Mais informações sobre
            os serviços oferecidos por essa empresa podem ser acessadas em{" "}
            <a href="https://moip.com.br/politica-de-privacidade/#1473893128423-fd332f00-25b2">
              https://moip.com.br/politica-de-privacidade/#1473893128423-fd332f00-25b2
            </a>
            .
          </li>
          <li>
            <strong>Empresas de métricas de acesso.</strong> Nós utilizamos as
            ferramentas Google Analytics e MixPanel para processamento e
            visualização de métricas de acesso. Tanto a política de privacidade,
            como os termos de uso dessas ferramentas podem ser acessados no site{" "}
            <a href="https://www.google.com/intl/pt/analytics/">
              https://www.google.com/intl/pt/analytics/
            </a>{" "}
            e{" "}
            <a href="https://mixpanel.com/legal/contact-us/">
              https://mixpanel.com/legal/contact-us/
            </a>
            .
          </li>
          <li>
            <strong>Ferramentas de publicidade.</strong> Nós utilizamos as
            ferramentas Facebook Ads, Google Adwords e LinkedIn Ads para
            personalização de anúncios de publicidade e retargeting. Para
            consultar a política de privacidade, bem como os termos de uso
            dessas ferramentas, acesse o site{" "}
            <a href="https://www.facebook.com/business/products/ads">
              https://www.facebook.com/business/products/ads
            </a>
            ,{" "}
            <a href="https://www.google.com.br/adwords/">
              https://www.google.com.br/adwords/
            </a>{" "}
            e{" "}
            <a href="https://www.linkedin.com/legal/privacy-policy">
              https://www.linkedin.com/legal/privacy-policy
            </a>
            .
          </li>
          <li>
            <strong>Ferramentas de e-mail marketing</strong>. As comunicações de
            e-mail marketing são processadas através de ferramentas de
            titularidade de terceiros, como a RD Station e a SendGrid. Para
            consultar a política de privacidade, bem como os termos de uso
            dessas empresas terceiras, acesse os sites
            <a href="https://resultadosdigitais.com.br/politica-de-privacidade/?_ga=2.90074395.1840501114.1532715171-1296177538.1532715171">
              https://resultadosdigitais.com.br/politica-de-privacidade/?_ga=2.90074395.1840501114.1532715171-1296177538.1532715171
            </a>
            e{" "}
            <a href="https://sendgrid.com/policies/privacy/services-privacy-policy/">
              https://sendgrid.com/policies/privacy/services-privacy-policy/
            </a>
            .
          </li>
        </ul>
        <p>
          Em nenhuma hipótese, as empresas aqui mencionadas estão autorizadas a
          utilizar suas Informações Pessoais de forma diversa do disposto acima,
          salvo em caso de seu consentimento expresso.
        </p>
        <p>
          As Informações Pessoais também poderão ser compartilhadas com
          autoridades policiais, judiciais, administrativas ou com o Ministério
          Público, em caso de ordem judicial que nos obrigue a disponibilizar
          Informações Pessoais às autoridades requisitantes.
        </p>
        <p>
          A transferência de Informações Pessoais também poderá ocorrer em caso
          de reestruturações societárias (aquisição, cisão ou fusão com outra
          empresa). Neste caso, nós iremos notificar você antes que suas
          Informações Pessoais sejam transferidas e fiquem sujeitas a outra
          política de privacidade.
        </p>
        <p>
          Em nenhuma hipótese as Informações Pessoais serão compartilhadas ou
          vendidas a outras organizações para fins comerciais, exceto para o
          fornecimento dos Serviços solicitados por você, quando houver seu
          consentimento específico para tanto.
        </p>
        <h3>4. SERVIÇOS FORNECIDOS POR TERCEIROS</h3>
        <p>
          Ao utilizar nossos Serviços, você poderá utilizar produtos/serviços
          fornecidos por Terceiros Parceiros. Nós não somos responsáveis pelas
          Políticas de Privacidade desses Terceiros Parceiros e recomendamos
          fortemente que você analise cuidadosamente essas políticas. O conteúdo
          dessas políticas pode ser encontrado nos links:{" "}
        </p>
        <p>
          <a href="https://www.cloudera.com/products/open-source.html">
            https://www.cloudera.com/products/open-source.html
          </a>
        </p>
        <p>
          <a href="https://www.elastic.co/gdpr" />
        </p>
        <h3>5. ARMAZENAMENTO DE DADOS</h3>
        <p>
          As Informações Pessoais coletadas pela plataforma são armazenadas pelo
          período de 6 (seis) meses contados da data de sua coleta. Após esse
          prazo, nós poderemos, a nosso exclusivo critério, excluir
          definitivamente quaisquer Informações Pessoais armazenadas em nossos
          servidores.
        </p>
        <p>
          Você poderá, a qualquer momento, solicitar a exclusão de suas
          Informações Pessoais coletadas pela plataforma. Contudo, nós poderemos
          armazenar os seus dados relativos ao IP e data e hora de acesso pelo
          prazo de 6 (seis) meses contados de sua coleta ou por prazo superior
          (caso solicitado por autoridade competente), conforme obrigação
          prevista na Lei nº. 12.965/2014 (“Marco Civil da Internet”). Após o
          término do referido prazo, você poderá também solicitar a exclusão
          definitiva de quaisquer registros de IP e data e hora de acesso.
        </p>
        <p>
          Você poderá, ainda, revisar, complementar e alterar as Informações
          Pessoais fornecidas por meio das páginas de perfil ou, se desejar,
          revogar seu consentimento e encerrar a sua conta mediante pedidos
          direcionados ao endereço de e-mail opengalaxy@opengalaxy.io.{" "}
        </p>
        <h3>6. SEGURANÇA</h3>
        <p>
          Nós utilizamos criptografia de chaves públicas, através de
          certificados digitais, para o acesso à plataforma e aos Serviços. A
          sua senha está cifrada na base de dados.
        </p>
        <p>
          Todas as Informações Pessoais estão sujeitas a práticas de segurança,
          que incluem medidas de proteção física e digital e buscam prevenir que
          terceiros não autorizados tenham acesso indevido às suas Informações
          Pessoais.
        </p>
        <p>
          É SUA RESPONSABILIDADE MANTER O AMBIENTE DE SEUS DISPOSITIVOS DE
          ACESSO SEGURO, MEDIANTE O USO DE FERRAMENTAS PRÓPRIAS, TAIS COMO
          ANTIVÍRUS E FIREWALL, E, AINDA, UTILIZAR SOFTWARE ATUALIZADOS E
          EFICIENTES PARA O ACESSO AOS SERVIÇOS. EM NENHUMA HIPÓTESE NÓS
          SOLICITAREMOS INFORMAÇÕES PESSOAIS VIA E-MAIL, ESPECIALMENTE
          INFORMAÇÕES RELATIVAS A SENHAS DE ACESSO. NÓS NÃO NOS
          RESPONSABILIZAMOS POR QUAISQUER COMUNICAÇÕES ELETRÔNICAS FRAUDULENTAS
          QUE COLETEM DADOS PESSOAIS DE SEUS USUÁRIOS (PHISHING).
        </p>
        <h3>7. SEUS DIREITOS</h3>
        <p>
          De acordo com a legislação sobre proteção de dados em vigor, você
          possui os seguintes direitos sobre as suas Informações Pessoais:
        </p>
        <p>
          (i) Direito à confirmação da existência de tratamento e acesso às
          Informações Pessoais;
          <br />
          (ii) Direito à correção de dados incompletos, inexatos ou
          desatualizados;
          <br />
          (iii) Direito à anonimização; bloqueio ou eliminação de dados
          desnecessários, excessivos ou tratados em desconformidade com a
          legislação em vigor;
          <br />
          (iv) Direito à portabilidade das Informações Pessoais para serviços de
          terceiros;
          <br />
          (v) Acesso a informação a respeito do compartilhamento das suas
          Informações Pessoais com terceiros, e<br />
          (vi) Direito à revogação, a qualquer tempo, do seu consentimento.
        </p>
        <h3>8. ALTERAÇÕES NESTA POLÍTICA DE PRIVACIDADE</h3>
        <p>
          Esta Política de Privacidade pode ser modificada por nós a qualquer
          tempo. As alterações efetuadas serão disponibilizadas no site da
          plataforma e comunicadas a você por meio do e-mail e/ou notificação no
          site.
        </p>
        <p>
          Esta Política de Privacidade é regida pelas leis da República
          Federativa do Brasil. Na hipótese de conflitos relacionados a esta
          Política de Privacidade, fica eleito o foro da cidade de São Paulo,
          Estado de São Paulo, para dirimir eventuais disputas, com renúncia de
          qualquer outro por mais privilegiado que seja.
        </p>
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;
