import React, { useCallback, useState, useEffect } from "react";
import CardSelector from "new-components/CardSelector";
import { Formik, Form } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Menu from "../../components/Menu/Menu";
import { data } from "./environments.json";
import "./CreateEnvironment.css";

export default function CreateEnvironment() {
  const [change, setChange] = useState(false);
  const [spaces, setSpaces] = useState([]);

  const initialValues = {
    environment: "",
  };

  const formValidation = Yup.object().shape({
    environment: Yup.string().required(
      "Enviroment not selected, select at least one environment."
    ),
  });

  const handleSubmit = useCallback((values, { resetForm }) => {
    Swal.fire(
      "Congratulations!",
      `${values.environment} added successfully!`,
      "success"
    ).then((res) => {
      if (res.value) {
        resetForm();
        setChange((prev) => !prev);
      }
    });
  }, []);

  useEffect(() => {
    (function Mount() {
      return setSpaces(data);
    })();
    return () => setSpaces([]);
  }, [change]);

  const RenderForm = useCallback(
    ({ values, errors, setFieldValue }) => (
      <Form className="createEnvironment-content__form">
        {spaces.map((env) => (
          <CardSelector
            id={`${env.title}-${new Date().getTime()}`}
            value={`${env.title}`}
            name="envs"
            title={env.title}
            text={env.text}
            onChange={(e) => setFieldValue("environment", e.target.value)}
            unicChoice
          />
        ))}
        <div className="createEnvironment-content__form-actions">
          <button
            type="subimt"
            disabled={!values.environment?.length}
            className={`createEnvironment-content__form-button ${
              !values.environment?.length ? "is-disabled" : ""
            }`}
          >
            Create Space
          </button>
        </div>
      </Form>
    ),
    [spaces]
  );
  return (
    <div className="createEnvironment">
      <Menu />
      <div className="createEnvironment-container">
        <div className="createEnvironment-header">
          <h2 className="createEnvironment-header__title">Create Spaces</h2>
        </div>
        <div className="createEnvironment-content">
          <h4 className="createEnvironment-content__title">
            Selec at leat on space.
          </h4>
          <Formik
            initialValues={initialValues}
            validation={formValidation}
            onSubmit={handleSubmit}
          >
            {RenderForm}
          </Formik>
        </div>
      </div>
    </div>
  );
}
