import React from "react";
import "./Status.css";

const Status = ({ children, status, delete_date, status_bar, msg_update }) => (
  <div className={`Status ${status?.replace(/\s/g, "")}`}>
    <div className="current-status">
      <span className="title">Current status - All services</span>
      <span className="text">{status}</span>
    </div>
    {delete_date ? (
      <span className="delete-msg">
        This Space will be deleted on {delete_date}
        <br />
        In order to continue using it, please Activate Space.
      </span>
    ) : (
      ""
    )}
    {status === "Deploying All Services" ? (
      <span>
        About {status_bar.time_string } minutes
        remaining.
      </span>
    ) : (
      ""
    )}
    {msg_update ? (
      <span>Services Uptime will update next time Space Turns Offline.</span>
    ) : (
      ""
    )}
    {children}
    {status === "Deploying All Services" ? (
      <div className="status-bar">
        <div
          className="bar"
          style={{
            width: `${(status_bar.partial_time * 100) / status_bar.full_time}%`
          }}
        />
      </div>
    ) : (
      ""
    )}
  </div>
);

export default Status;
