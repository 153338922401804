import axios from "axios";

const actionDeleteUserAuth = id => {
  return axios
    .post(`https://${process.env.REACT_APP_AUTH_DOMAIN}/oauth/token`, {
      grant_type: "client_credentials",
      client_id: process.env.REACT_APP_AUTH_CLIENT,
      client_secret: process.env.REACT_APP_AUTH_SECRET,
      audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
    })
    .then(resp => {
      return axios({
        url: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/users/${id}`,
        method: "delete",
        headers: { Authorization: `Bearer ${resp.data.access_token}` }
      });
    });
};

export default actionDeleteUserAuth;
