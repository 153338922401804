
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import environments from "./environments";
import packs from "./packs";
import stage from "./stage";
import services from "./services";
import robustness from "./robustness";
import applications from "./applications";
import added_services from "./added_services";
import users from "./users";
import roles from "./roles";
import teams from "./teams";
import credit_card from "./credit_card";
import id_token from "./id_token";
import stage_resource from "./stage_resource";
import plugins from "./plugins";
import clouds from "./clouds";
import cloud_price from "./cloud_price";

const loading = (state = false, action) => {
  switch (action.type) {
    case "LOADING":
      return !!action.state;
    default:
      return state;
  }
};

const user_id = (state = "", action) => {
  switch (action.type) {
    case "ADD_USERID":
      return action.id;
    default:
      return state;
  }
};

const user_email = (state = "", action) => {
  switch (action.type) {
    case "ADD_USER_EMAIL":
      return action.email;
    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case "ADD_USER":
      return action.data;
    default:
      return state;
  }
};

const user_level = (state = 3, action) => {
  switch (action.type) {
    case "ADD_USER_LEVEL":
      return action.data;
    default:
      return state;
  }
};

const prices = (state = [], action) => {
  switch (action.type) {
    case "SEND_PRICE":
      return [...action.data];
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  loading,
  environments,
  packs,
  stage,
  services,
  robustness,
  applications,
  added_services,
  user_id,
  users,
  roles,
  teams,
  user,
  user_level,
  user_email,
  credit_card,
  prices,
  id_token,
  stage_resource,
  plugins,
  clouds,
  cloud_price,
});

export default persistReducer(persistConfig, rootReducer);
