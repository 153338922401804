import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert2";
import { useObservable, useDebounce } from "hooks";
import Menu from "components/Menu/Menu";
import actionAPI from "actions/actionAPI";
import Details from "new-components/Environment/Details";
import Access from "new-components/Environment/Access";
// import BillingReport from "new-components/Environment/BillingReport";
import DeleteSpace from "new-components/Environment/DeleteSpace";
import Security from "new-components/Environment/Security";
import Backup from "new-components/Environment/Backup";
// import Architeture from "new-components/Environment/Architeture";
import Settings from "semantix-ui/lib/Icons/Settings";
import "./EnvironmentDetail.css";
import "new-components/TableServices/TableServices.css";

export default function EnvironmentDetail(props) {
  const store = useSelector((s) => ({ ...s }));
  const { match, history, location, mixpanel } = props;
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [state, setState] = useState({});
  const [tab, setTab] = useState("details");
  const userID = useObservable(store?.user?.id_account);
  const userLevel = useObservable(store.user_level);
  const idToken = useObservable(store.id_token);
  const addedServices = useObservable(store.added_services);
  const reloadServices = useDebounce(reload, 15000);

  // utils

  const getServices = () =>
    actionAPI(idToken, `/space/${match.params.id}`, null, "GET");

  const success = () => {
    swal.fire("Saved", "", "success").then(() => {
      history.push(`/refresh/${location.pathname}`);
    });
    setLoading(false);
  };

  const error = () => {
    swal.fire({
      type: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
    setLoading(false);
  };

  const swalDeleteAlert = {
    remove: () =>
      swal.fire("Deleted!", "Your space has been deleted.", "success"),
    ask: () =>
      swal.fire(
        "Deleting was requested successfully.",
        "Our specialists will contact you.",
        "success"
      ),
    resetPassword: () =>
      swal.fire({
        title: "Are you shure?",
        text: "Do you really want to reset your password?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reset it!",
      }),

    resetSuccess: (res) =>
      swal.fire({
        title: "Save your Elasticsearch and Kibana password",
        icon: "success",
        html: `
          <p>These credentials provide superuser access to Elasticsearch Elasticsearch and Kibana</p>
          <p>
          <small>Once closed, you'll can't see this passoword anymore!</small>
          </p>
          <br />
          <p>
            <span>Username: </span>
            <span>${res?.elastic_username}</span>
          </p>
          <p>
            <span >Password: </span>
            <span contentEditable="true">${res?.elastic_password}</span>
          </p>
        `,
      }),
  };

  // Mount Data Components
  useEffect(() => {
    (async () => {
      const response = await getServices();
      if (response) {
        setState({
          ...response,
          development: { hour: 0, value: 0 },
          consulting: { hour: 0, value: 0 },
          status_bar: { full_time: 0, partial_time: 0 },
        });
        setLoading(false);
      }
    })();
    return () => setLoading(true);
  }, [idToken]);

  useEffect(() => {
    (async () => {
      if (reloadServices) {
        let response = await getServices();
        setState((prev) => ({ ...prev, response }));
      }
      setReload((prev) => !prev);
    })();
  }, [reloadServices]);

  // HANDLERS (AÇÕES)
  const handleDeleteSpace = (type) => () => {
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      })
      .then((res) => {
        if (res.value) {
          actionAPI(idToken, `/space/${match.params.id}`, null, "DELETE").then(
            () => {
              mixpanel.track("Delete Space", {
                name: state?.nm_space,
                stage: state?.stage?.nm_stage,
                id_environment: match.params.id,
              });
              swalDeleteAlert[type].then(() => {
                history.push({ pathname: "/environments" });
              });
            }
          );
        }
      });
  };
  const handleSaveChanges = () => {
    addedServices.forEach((item) => {
      setLoading(true);
      if (!item.type) {
        const body = {
          professional: {
            development: state.development,
            consulting: state.consulting,
          },
        };
        actionAPI(idToken, `/space/${match.params.id}`, body, "PUT")
          .then(success)
          .catch(error);
      }
    });
  };

  const HandleResetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    swalDeleteAlert.resetPassword().then((res) => {
      if (res.value) {
        actionAPI(idToken, "/reset_elastic_password", null).then((reset) => {
          console.log(reset);
          if (!!reset) {
            swalDeleteAlert.resetSuccess(reset).then((final) => {
              if (final.value) {
                setLoading(false);
              }
            });
          }
        });
      }
    });
  };

  // RESET PASSWORD WHITE LIST

  const RPwhiteList = [365, 366, 367, 270, 83];

  // TAB VIEWS
  const TabViews = useMemo(() => {
    switch (tab) {
      /*       case "architecture":
        return <Architeture idEnvironment={match.params.id} />; */
      case "security":
        return <Security idEnvironment={match.params.id} />;
      /*       case "backup":
        return <Backup idEnvironment={match.params.id} />; */
      case "delete_space":
        return (
          <DeleteSpace
            deleteEnvironment={handleDeleteSpace("remove")}
            askForDeleting={handleDeleteSpace("ask")}
            stage={state.stage?.nm_stage}
          />
        );
      case "access":
        return (
          <Access
            idEnvironment={match.params.id}
            removeTeam={(item) => item}
            addTeam={(item) => item}
            removeUser={(item) => item}
            addUser={(item) => item}
          />
        );
      default:
        return (
          <Details
            idEnvironment={match.params.id}
            services={state?.links}
            consulting={state?.consulting}
            link={state?.monitor_link}
            userLevel={userLevel}
            frequency={state.uptime?.ds_frequency}
            start_time={state.uptime?.dt_start}
            stop_time={state.uptime?.dt_stop}
            blocked={state?.dt_blocked_at}
            delete_date={state.uptime?.dt_delete_space}
            status_name={state?.ds_status}
            status={state?.id_status}
            status_bar={state.status_bar}
            save={(obj) => {
              setState((prev) => ({
                ...prev,
                consulting: obj.consulting,
              }));
              handleSaveChanges();
            }}
            stage={state.stage?.nm_stage}
            msg_update={
              state?.updating_uptime &&
              state?.ds_status === "All Services Online"
            }
          />
        );
    }
  }, [tab, state]);

  //RENDER

  return (
    <div className="EnvironmentDetail main-holder">
      <Menu />
      <div className="content-holder">
        <div className={`wrapper ${loading ? "loading" : ""}`}>
          <div className="env-head">
            <div className="col-title">
              <h2 className="title-page">{state.nm_space}</h2>
              <p className="stage-name">
                {state.stage?.nm_stage} -{" "}
                {state.cloud_region?.nm_cloud_provider}
              </p>
              {state?.dt_blocked_at && (
                <span className="badge-blocked">Blocked</span>
              )}
              {state?.updating_uptime &&
                state?.ds_status !== "All Services Online" && (
                  <span className="badge-updating">Updating</span>
                )}
            </div>
            {state?.dt_blocked_at && (
              <div className="col-activate">
                <Link
                  to={{
                    pathname: `/activate-space/${match.params.id}`,
                    state: {
                      frequency: state.uptime?.ds_frequency,
                      start_time: state.uptime?.dt_start,
                      stop_time: state.uptime?.dt_stop,
                      activateSpace: true,
                    },
                  }}
                  className="main-button"
                >
                  Activate Space
                </Link>
                <span className="text">
                  Purchase Uptime to continue using this Space
                </span>
              </div>
            )}
          </div>
          <div className="tabEnvironment">
            <button
              className={`tab-button ${tab === "details" ? "active" : ""}`}
              onClick={() => setTab("details")}
            >
              Services
            </button>
            {/* <button
              className={`tab-button ${tab === "architecture" ? "active" : ""}`}
              onClick={() => setTab("architecture")}
            >
              Architecture
            </button> */}
            <button
              className={`tab-button ${tab === "security" ? "active" : ""}`}
              onClick={() => setTab("security")}
            >
              Security
            </button>
           {/*  {state.cloud_region?.nm_cloud_provider === "Azure" ||
            state.stage?.nm_stage === "Enterprise" ? (
              ""
            ) : (
              <button
                className={`tab-button ${tab === "backup" ? "active" : ""}`}
                onClick={() => setTab("backup")}
              >
                Backup
              </button>
            )} */}

            <div className="SubTab">
              <span className="SettingsButton">
                <Settings />
              </span>
              <div className="SubTabOver">
                <button
                  className={`tab-button ${tab === "access" ? "active" : ""}`}
                  onClick={() => setTab("access")}
                >
                  Access
                </button>
                {!!RPwhiteList.includes(userID) && (
                  <button
                    onClick={(e) => HandleResetPassword(e)}
                    className="tab-button"
                  >
                    Reset password
                  </button>
                )}
                {state?.ds_status !== "Deploying All Services" ? (
                  <React.Fragment>
                    {userLevel <= 2 && (
                      <button
                        className={`tab-button ${
                          tab === "delete_space" ? "active" : ""
                        }`}
                        onClick={() => setTab("delete_space")}
                      >
                        Delete Space
                      </button>
                    )}
                  </React.Fragment>
                ) : (
                  <span className="tab-button disabled">Delete Space</span>
                )}
              </div>
            </div>
          </div>
          {TabViews}
        </div>
      </div>
    </div>
  );
}
