import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useObservable } from "hooks";
import Card, { CardList } from "new-components/Box/Card";
import Menu from "components/Menu/Menu";
import SectionHeader from "new-components/SectionHeader";
import Container from "new-components/Box/Container";
import Input from "new-components/Form/Input";
import actionAPI from "actions/actionAPI";
import actionCreateUserAuth from "actions/actionCreateUserAuth";
import * as utils from "./utils";
import "./User.css";

export default function User(props) {
  const { match, location, history } = props;
  const { params } = match;
  const { state } = location;
  const dispatch = useDispatch();
  const store = useSelector((s) => s);
  const newUser = useObservable(!params?.id);
  const roles = useObservable(store?.roles);
  const [role, setRole] = useState();
  const idToken = useObservable(store?.id_token);
  const userLevel = useObservable(store?.user_level);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    email: { value: state?.ds_email || null, valid: !!state },
    name: { value: state?.nm_account || null, valid: !!state },
    password: { value: "", valid: false },
    confirm_passowrd: { value: "", valid: false },
  });
  const getRoles = async () => actionAPI(idToken, "/role", null, "GET");
  const errorAlert = (err) => {
    if (err.response) {
      swal
        .fire({
          ...utils.alert.allError,
          text: err.response.data.message,
        })
        .then((swalRes) => {
          if (swalRes.value) {
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        if (!roles?.length) {
          const response = await getRoles();
          dispatch({
            type: "ADD_ROLES",
            data: response,
          });
          setLoading(false);
          setRole(!params?.id ? response[0]?.id_role : state?.id_role);
          return;
        }
        setRole(!params?.id ? roles[0]?.id_role : state?.id_role);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [idToken, roles]);

  const handleSendInvite = (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, name, password } = formData;
    actionCreateUserAuth(email.value, password.value)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const body = {
            nm_account: name.value,
            ds_email: email.value,
            id_role: role,
            ds_auth_id_account: res.data.user_id,
            nr_phone: "nenhum",
            ds_job_title: "nenhum",
          };
          actionAPI(idToken, "/account/invite", body)
            .then((_res) => {
              setLoading(false);
              if (!_res?.message && !!_res?.id_account) {
                swal.fire(utils.alert.inviteSuccess).then((swalRes) => {
                  if (swalRes.value) {
                    history.push({
                      pathname: `/user/${_res.id_account}`,
                    });
                  }
                });
              } else {
                swal
                  .fire({ ...utils.alert.allError, text: _res.message })
                  .then((swalRes) => {
                    if (swalRes.value) {
                      setLoading(false);
                    }
                  });
              }
            })
            .catch(errorAlert);
        }
      })
      .catch(errorAlert);
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    setLoading(true);
    const { name } = formData;
    const body = {
      nm_account: name.value,
      id_role: role,
      nr_phone: "nenhum",
      ds_job_title: "nenhum",
    };
    actionAPI(idToken, `/account/${params.id}`, body, "PUT")
      .then((response) => {
        swal
          .fire({ ...utils.alert.update, text: response.message })
          .then((res) => {
            if (res.value) {
              history.push(`/user/${params.id}`);
            }
          });
      })
      .catch(errorAlert);
  };

  const handleInputField = useCallback(
    (value, field) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    [formData]
  );

  return (
    <div className="User main-holder">
      <Menu />
      <div className="content-holder">
        <div className={`wrapper ${loading ? "loading" : ""}`}>
          <Link to="/settings" className="button-back">
            {"<"} Back to users
          </Link>
          <h2 className="title-page">
            {newUser ? "Invite User" : "Edit User"}
          </h2>
          {userLevel < 2 && (
            <div>
              <SectionHeader
                title="User Identification"
                description="Define user identification for login."
              />
              <Container>
                <div className="field">
                  <label htmlFor="nm_account">Full Name</label>
                  <Input
                    type="text"
                    name="name"
                    id="nm_account"
                    defaultValue={state?.nm_account}
                    placeholder="Insert full name"
                    validation={["required"]}
                    disabled={!!state?.nm_account}
                    message="Please enter a name"
                    setValue={(val) => handleInputField(val, "name")}
                  />
                </div>
                <div className="field">
                  <label htmlFor="ds_email">Email Address</label>
                  <Input
                    type="email"
                    name="email"
                    id="ds_email"
                    defaultValue={state?.ds_email}
                    placeholder="Insert email address"
                    validation={["required", "email"]}
                    message="Please enter a valid email"
                    disabled={!newUser}
                    setValue={(val) => handleInputField(val, "email")}
                  />
                </div>
              </Container>

              <SectionHeader
                title="Set User Role"
                description="Setup user level of access to Open Galaxy."
              />
              <div className="user-role">
                <CardList>
                  {roles.map((item) => (
                    <Card
                      key={item.id_role}
                      variant={item.id_role === role ? "highlight" : ""}
                    >
                      <span className="title">{item.nm_role}</span>
                      <span className="description">{item.ds_role}</span>
                      <button
                        className={`button-select ${
                          item.id_role === role ? "selected" : ""
                        }`}
                        onClick={() => setRole(item.id_role)}
                      >
                        {item.id_role === role ? "Selected" : "Select"}
                      </button>
                    </Card>
                  ))}
                </CardList>
              </div>

              {!!newUser && (
                <Container>
                  <SectionHeader title="Create Password" description="" />
                  <ul className="password-rules">
                    <li>
                      <strong>Password rules:</strong>
                    </li>
                    <li>
                      - Use at least one of those special characters: ! @ # $ %
                      ^ & *
                    </li>
                    <li>
                      - Lower case (a-z), upper case (A-Z) and numbers (0-9)
                    </li>
                    <li>- Must have 8 characters in length</li>
                  </ul>
                  <div className="new-password">
                    <div className="field">
                      <label>New Password</label>
                      <div style={{ width: "25%" }}>
                        <Input
                          type="password"
                          name="new-password"
                          placeholder="insert the new password"
                          validation={["required"]}
                          setValue={(val) => handleInputField(val, "password")}
                          message="Please insert a valid password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="new-password">
                    <div className="field">
                      <label>Confirm New Password</label>
                      <div style={{ width: "25%" }}>
                        <Input
                          type="password"
                          name="confirm-password"
                          placeholder="Confirm the new password"
                          equalVal={formData.password.value}
                          validation={["required", "password"]}
                          setValue={(val) =>
                            handleInputField(val, "confirm_password")
                          }
                          message="Please confirm the password"
                        />
                      </div>
                    </div>
                  </div>
                </Container>
              )}
              <div className="invite">
                {newUser ? (
                  <div
                    className={`box-disable ${
                      formData.name?.valid &&
                      formData.email?.valid &&
                      formData.password?.valid &&
                      formData.confirm_password?.valid
                        ? ""
                        : "disabled"
                    }`}
                  >
                    <button className="main-button" onClick={handleSendInvite}>
                      Invite user
                    </button>
                  </div>
                ) : (
                  <div
                    className={`box-disable ${
                      formData.name?.valid && formData.email?.valid
                        ? ""
                        : "disabled"
                    }`}
                  >
                    <button className="main-button" onClick={handleSaveChanges}>
                      Save changes
                    </button>
                  </div>
                )}
                <span className="text">or</span>
                <Link to="/settings" className="cancel">
                  Cancel
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
