import React from "react";
import './Table.css';

export const TableCol = (props) => (
  <div className="TableCol" style={{width: `${props.size}%`}}>
    {props.children}
  </div>
);

export const TableRow = (props) => (
  <div className={`TableRow ${props.cssClass}`}>
    {props.children}
  </div>
);

const Table = props => (
  <div className="Table">
    <div className="TableHeader">
      {props.header.map(item => <div className="TableCol" key={item[0]} style={{width: `${item[1]}%`}}>{item[0]}</div>)}
    </div>
    <div className="TableContent">{props.children}</div>
  </div>
);

export default Table;
