import React from "react";
import { Redirect, Route, Switch, Router } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import reducer from "./reducers/reducers";
import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from "react-mixpanel";

// Pages
import Login from "./pages/Login/Login";

import Environment from "./new-pages/Environment";
import EnvironmentDetail from "./new-pages/EnvironmentDetail";
import CreateEnvironment from "./new-pages/CreateEnvironment";
import Settings from "./new-pages/Settings";
import User from "./new-pages/User";
import UserSpaces from "./new-pages/UserSpaces";
import Team from "./new-pages/Team";
import CreditCard from "./new-pages/CreditCard";
import CreateCompany from "./new-pages/CreateCompany";
import CreateAccount from "./new-pages/CreateAccount";
import Page404 from "./new-pages/Page404";
import TermsOfService from "./new-pages/TermsOfService";
import PrivacyPolicy from "./new-pages/PrivacyPolicy";
import VerifyEmail from "./new-pages/VerifyEmail";
import AccessDenied from "./new-pages/AccessDenied";
import ServerError from "./new-pages/ServerError";
import Plugins from "./new-pages/Plugins";
import ServicesUptime from "./new-pages/ServicesUptime";
import BillingDate from "./new-pages/BillingDate";

// Components
import Callback from "./components/Callback/Callback";

import auth from "./Auth/Auth";
import history from "./history";
import TeamSpaces from "./new-pages/TeamSpaces";

mixpanel.init("42f9a42c80fc57e0eb87830c2e43422b");

const handleAuthentication = ({ location }, store) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication(store).then((pr) => {
      if (!mixpanel.people._identify_called()) {
        mixpanel.identify(auth.profile.email);
        mixpanel.people.set({
          $email: auth.profile.email,
          name: auth.profile.email.split("@")[0],
          domain: auth.profile.email.match(/@([a-zA-Z]+)\./)
            ? auth.profile.email.match(/@([a-zA-Z]+)\./)[1]
            : "",
          $last_login: new Date(),
        });
      }
    });
  }
};

const middleware = [thunk];
if (process.env.REACT_APP_ENV !== "production") {
  middleware.push(createLogger());
}

const store = createStore(reducer, applyMiddleware(...middleware));

export const makeMainRoutes = () => {
  return (
    <MixpanelProvider mixpanel={mixpanel}>
      <Provider store={store}>
        <div>
          <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Login auth={auth} {...props} />}
              />
              <Route
                path="/environments"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <Environment auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/services-uptime/:id"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <ServicesUptime auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/activate-space/:id"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <ServicesUptime auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/billing-date"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <BillingDate auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/environment/:id"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <EnvironmentDetail
                      auth={auth}
                      {...props}
                      mixpanel={mixpanel}
                    />
                  )
                }
              />
              <Route
                path="/create-environment"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <CreateEnvironment
                      auth={auth}
                      {...props}
                      mixpanel={mixpanel}
                    />
                  )
                }
              />
              <Route
                path="/settings"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <Settings auth={auth} {...props} />
                  )
                }
              />
              <Route
                path={["/invite-user", "/edit-user/:id"]}
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <User auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/user/:id"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <UserSpaces auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/create-team"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <Team auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/add-credit-card/"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <CreditCard auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/edit-credit-card/:id"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <CreditCard auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/edit-team/:id"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <Team auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/team/:id"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <TeamSpaces auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/callback"
                render={(props) =>
                  handleAuthentication(props, store) ? (
                    <Redirect to="/" />
                  ) : (
                    <Callback auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/refresh"
                component={({ history, location, match }) => {
                  history.replace({
                    ...location,
                    pathname: location.pathname.substring(match.path.length),
                  });
                  return null;
                }}
              />
              <Route
                path="/create-company"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <CreateCompany auth={auth} {...props} />
                  )
                }
              />
              <Route
                path="/create-account"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <CreateAccount auth={auth} {...props} />
                  )
                }
              />

              <Route
                path="/terms-of-service"
                render={(props) => <TermsOfService auth={auth} {...props} />}
              />

              <Route
                path="/privacy-policy"
                render={(props) => <PrivacyPolicy auth={auth} {...props} />}
              />

              <Route
                path="/plugins"
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <Plugins auth={auth} {...props} />
                  )
                }
              />

              <Route
                path="/verify-email"
                render={(props) => <VerifyEmail auth={auth} {...props} />}
              />

              <Route
                path="/access-denied"
                render={(props) => <AccessDenied auth={auth} {...props} />}
              />

              <Route
                path="/not-found"
                render={(props) => <Page404 auth={auth} {...props} />}
              />

              <Route
                path="/server-error"
                render={(props) => <ServerError auth={auth} {...props} />}
              />

              <Route
                render={(props) =>
                  !auth.isAuthenticated() ? (
                    <Redirect to="/" />
                  ) : (
                    <Page404 auth={auth} {...props} />
                  )
                }
              />
            </Switch>
          </Router>
        </div>
      </Provider>
    </MixpanelProvider>
  );
};
