import axios from "axios";

const actionAPI = async (token, url, data, method = "post") => {
  let dataObj = {
    url: `https://${process.env.REACT_APP_API_BASE + url}`,
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: data ? data : {},
  };
  if (token) {
    const { data } = await axios(dataObj);
    return data;
    // .catch(err => {
    //   if (err.response.status > 499) {
    //     history.push("/server-error", {
    //       error: err.response.data,
    //       status: err.response.status
    //     });
    //   } else {
    //     history.push("/not-found");
    //   }
    // });
  } else {
    console.error("no id_token found");
  }
};

export default actionAPI;
