export const alerts = {
  removeUser: {
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Yes, delete it!"
  },
  removeSpace: {
    title: "Are you sure?",
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Yes, delete it!"
  },
  error: {
    type: "error",
    title: "Oops...",
    text: "Something went wrong!"
  }
};
