import React, { useState, useEffect, useMemo } from "react";
import { useSelector, userDispatch, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import { useObservable } from "hooks";
import Menu from "components/Menu/Menu";
import AddField from "new-components/Settings/AddField";
// import addTeam from "new-components/Settings/AddTeam";
import Container from "new-components/Box/Container";
import Table, { TableCol, TableRow } from "new-components/Table";
import actionAPI from "actions/actionAPI";
import actionSimpleAPI from "actions/actionSimpleAPI";
import actionDeleteUserAuth from "actions/actionDeleteUserAuth";
import * as utils from "./utils";
import "./UserSpaces.css";

export default function UserSpaces(props) {
  const store = useSelector((s) => s);
  const dispatch = useDispatch();
  const { match, history, location } = props;
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [addedSpaces, setAddedSpaces] = useState([]);
  const spaces = useObservable(store.environments);
  const idToken = useObservable(store.id_token);

  const reqs = [
    actionAPI(idToken, `/account/${match.params.id}/spaces`, null, "GET"),
    actionAPI(idToken, `/account/${match.params.id}`, null, "GET"),
  ];

  const showErrorMessage = (error) => {
    const { response } = error;
    if (response?.data) {
      swal.fire(response.data?.message, "", "error").then((result) => {
        if (result.value) {
          setLoading(false);
        }
      });
    }
  };

  const getSpaces = async () =>
    await actionAPI(idToken, "/company/spaces", null, "GET");

  useEffect(() => {
    (async function Mount() {
      try {
        const [resSpaces, resUserInfo] = await axios.all(reqs);
        if (resSpaces && resUserInfo) {
          setUserData(resUserInfo);
          setAddedSpaces(resSpaces);
          if (!spaces.length) {
            const resAccounts = await getSpaces();
            dispatch({
              type: "UPDATE_ENVIRONMENTS",
              data: resAccounts,
            });
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [idToken]);

  // handlers

  const handleaddSpace = (s) => {
    const hasSpaces = addedSpaces.find((i) => i.id_space, s.id_space);
    if (!hasSpaces) {
      setLoading(true);
      actionAPI(idToken, `/space/${s.id_space}/${match.params.id}`)
        .then(() => {
          setAddedSpaces((prev) => ({ ...prev, s }));
          setLoading(false);
        })
        .catch(showErrorMessage);
    }
  };

  const handleaRemoveSpace = (s) => {
    swal.fire(utils.alerts.removeSpace).then((result) => {
      if (result.value) {
        setLoading(true);
        actionSimpleAPI(
          idToken,
          `/space/${s.id_space}/${match.params.id}`,
          null,
          "DELETE"
        )
          .then((response) => {
            if (response.status === 200) {
              swal.fire(response?.data?.message, "", "success").then((res) => {
                if (res.value) {
                  history.push("/environments");
                  // setAddedSpaces(prev => prev.filter(i => i.id_space !== s.id_space));
                  return;
                }
              });
            }
          })
          .catch(showErrorMessage);
      }
    });
  };

  const handleREmoveUser = () => {
    swal.fire(utils.alerts.removeUser).then((res) => {
      if (res.value) {
        actionDeleteUserAuth(userData.ds_auth_id_account).then((resp) => {
          actionAPI(idToken, `/space/${match.params.id}`, null, "DELETE")
            .then((response) => {
              swal
                .fire("User deleted!", response.message, "success")
                .then((r) => {
                  history.push({ pathname: "/settings/" });
                });
            })
            .catch((err) => {
              swal.fire(utils.alerts.error);
            });
        });
      }
    });
  };

  // renders

  const headUser = useMemo(
    () =>
      !!Object.keys(userData).length && (
        <div className="head-user">
          <div className="block-user-name">
            <h2 className="title-page">{userData.nm_account}</h2>
            <p>{userData.ds_email}</p>
          </div>
          <Link
            to={{
              pathname: `/edit-user/${match.params.id}`,
              state: { ...userData },
            }}
            className="main-button"
          >
            Edit user
          </Link>
        </div>
      ),
    [userData]
  );

  const mapServices = (it) => (
    <div className="service" key={it.id_tool}>
      <div className="service-text">
        <span className="qtd">1x</span>
        <span className="version">{it.ds_version}</span>
      </div>
      <div className="service-img">
        <img src={it.ds_logo} alt={it.ds_version} />
      </div>
    </div>
  );

  const mapSpaces = (item) => (
    <TableRow cssClass="space" key={item.id_space}>
      <TableCol size={50}>
        <div className="space-name">
          <Link to="/">{item.nm_space}</Link>
          <span>{item.pack?.nm_stage}</span>
        </div>
      </TableCol>
      <TableCol size={35}>
        <div className="services">{item?.services?.map(mapServices)}</div>
      </TableCol>
      <TableCol size={15}>
        <button className="remove" onClick={() => handleaRemoveSpace(item)}>
          Revoke Access
        </button>
      </TableCol>
    </TableRow>
  );

  const TableContent = useMemo(() => {
    if (addedSpaces.length && spaces.length) {
      return (
        <>
          {addedSpaces.map(mapSpaces)}
          <TableRow cssClass="table-footer">
            <AddField
              environments={spaces}
              addSpace={(space) => handleaddSpace(space)}
              label={"Add spaces for this user"}
            />
          </TableRow>
        </>
      );
    }
    return (
      <TableRow>
        <div className="no-content">
          <span className="title">
            This user does not have access to spaces yet.
          </span>
          <span className="text">Start adding spaces for this user below</span>
        </div>
      </TableRow>
    );
  }, [addedSpaces, spaces]);

  const DangerZone = useMemo(() => {
    let { id_account: account, nm_account: name } = userData;
    if (account && account !== parseInt(match.params.id)) {
      return (
        <>
          <h2 className="title-page">Danger Zone</h2>
          <div className="danger-zone">
            <div className="text">
              <span className="title">Remove {name} from this account</span>
              <span>
                They will not have access to OpenGalaxy account. Be aware this
                will not affect access to services.
              </span>
            </div>
            <button className="main-button" onClick={() => handleREmoveUser()}>
              Remove user
            </button>
          </div>
        </>
      );
    }
    return void 0;
  }, [userData]);

  return (
    <div className="UserSpaces main-holder">
      <Menu />
      <div className="content-holder">
        <div className={`wrapper ${loading ? "loading" : ""}`}>
          <Link to="/settings" className="button-back">
            {"<"} Back to users
          </Link>
          {headUser}
          <h2 className="title-page">Spaces</h2>
          <Container cssClass="Table-items">
            <Table
              header={[
                ["Name", 50],
                ["Services", 50],
              ]}
            >
              {TableContent}
            </Table>
          </Container>
          {DangerZone}
        </div>
      </div>
    </div>
  );
}
