import auth0 from "auth0-js";
import { useSelector } from "react-redux";
import history from "../history";

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      // the following three lines MUST be updated
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/userinfo`,
      clientID: process.env.REACT_APP_AUTH_CLIENT,
      redirectUri: `${window.location.origin}/callback`,
      responseType: "token id_token",
      scope: "openid email",
    });

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    return !!this.getIdToken() || new Date().getTime() < this.expiresAt * 1000;
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication(store) {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          if (
            err.errorDescription ===
            "Please verify your email before logging in."
          ) {
            history.push("/verify-email");
          } else {
            history.push("/access-denied");
          }
          return reject(err);
        }
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.idToken = authResult.idToken;
        this.profile = authResult.idTokenPayload;

        if (store) {
          store.dispatch({
            type: "SET_IDTOKEN",
            data: authResult.idToken,
          });
        }
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000;
        resolve();
        if (window.pendo) {
          window.pendo.initialize({
            visitor: {
              id: this.profile.sub,
              email: this.profile.email,
            },
            account: {
              id: "opengalaxy",
            },
          });
        }
        history.push("/");
      });
    });
  }

  logout() {
    // clear id token, profile, and expiration
    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;

    this.auth0.logout({
      returnTo: window.location.origin,
      client_id: process.env.REACT_APP_AUTH_CLIENT,
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;
