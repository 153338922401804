import React, { Component } from "react";

import Table, { TableRow, TableCol } from "../../Table";
import actionAPI from "../../../actions/actionAPI";

class PurchaseReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      data: [],
      total: 0,
      loading: false
    };
  }
  componentDidMount() {
    this.update();
  }

  componentWillReceiveProps(nexProps) {
    this.setState(
      {
        services: nexProps.services
      },
      () => this.update()
    );
  }
  update() {
    if (this.state.services.length) {
      this.setState({ loading: true });
      actionAPI(this.props.id_token, "/marketplace/cost", {
        services: this.state.services
      }).then(resp => {
        this.setState({
          data: resp.services,
          total: resp.total,
          loading: false
        });
      });
    } else {
      this.setState({
        data: [],
        total: 0
      })
    }
  }
  render() {
    const { data, total, loading } = this.state;
    return (
      <div className={loading ? "loading" : ""}>
        <Table
          header={[["Plugin", 50], ["Quantity of Spaces", 30], ["Price", 20]]}
        >
          {data.length ? (
            data.map(item => (
              <TableRow>
                <TableCol size={50}>{item.name}</TableCol>
                <TableCol size={30}>{item.qtd}</TableCol>
                <TableCol size={20}>
                  {item.price.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "BRL"
                  })}
                </TableCol>
              </TableRow>
            ))
          ) : (
            <div className="no-item">
              <span className="title">
                Select options above so you can see your space pricing
              </span>
            </div>
          )}
          <TableRow cssClass="total">
            <TableCol size={100}>
              Total price:{" "}
              {total.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "BRL"
              })}
            </TableCol>
          </TableRow>
        </Table>
      </div>
    );
  }
}

export default PurchaseReview;
