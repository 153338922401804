import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import MaskedInput from "react-text-mask";
import swal from "sweetalert2";

import ValidCnpf from "../../utils/validar-cnpj";
import ValidCpf from "../../utils/validar-cpf";

import Menu from "../../components/Menu/Menu";
import SectionHeader from "../../new-components/SectionHeader";

import actionSimpleAPI from "../../actions/actionSimpleAPI";

import "./CreditCard.css";

const iugu = window.Iugu ? window.Iugu : {};

class CreditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "legal-entity",
      loading: false,
      new_user: props.match.params.id ? false : true,
      user_level: props.user_level
    };
  }

  componentDidMount() {
    iugu.setAccountID("B75EE9361CF8400AB5FED0EFF6EF527A");
    iugu.setTestMode(false);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      user_level: nextProps.user_level
    });
  }

  onSelect(val) {
    this.setState({
      type: val
    });
  }

  saveCreditCard(values) {
    const _self = this;
    const _state = this.state;
    if (this.state.new_user) {
      this.setState({
        loading: true
      });

      iugu.createPaymentToken(
        document.getElementById("form-add-card"),
        function(response) {
          if (response.errors) {
            _self.setState({
              loading: false
            });
            swal.fire({
              type: "error",
              title: "Oops...",
              text: "Erro salvando cartão"
            });
          } else {
            if (_self.props.user.credit_card) {
              actionSimpleAPI(_self.props.id_token, "/billing/card/add", {
                document_number:
                  _state.type === "legal-entity"
                    ? values.cnpj.replace(/\D/g, "")
                    : values.cpf.replace(/\D/g, ""),
                zip_code: values.cep.replace(/\D/g, ""),
                number: values.address_number,
                street: values.street,
                brand: response.extra_info.brand,
                expire_date: values.expiration,
                identification: response.extra_info.bin,
                neighborhood: values.neighbordhood,
                city: values.city,
                document_type: _state.type === "legal-entity" ? "CNPJ" : "CPF",
                token: response.id
              })
                .then(resp => {
                  _self.setState({
                    loading: false
                  });
                  swal
                    .fire({
                      title: "Credit Card added",
                      text: "",
                      type: "success"
                    })
                    .then(result => {
                      if (result.value) {
                        _self.props.history.push({
                          pathname: "/settings",
                          hash: "#billing-information"
                        });
                      }
                    });
                })
                .catch(err => {
                  _self.setState({
                    loading: false
                  });
                  swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Something went wrong!"
                  });
                });
            } else {
              _self.props.history.push({
                pathname: "/billing-date",
                state: {
                  document_number:
                    _state.type === "legal-entity"
                      ? values.cnpj.replace(/\D/g, "")
                      : values.cpf.replace(/\D/g, ""),
                  zip_code: values.cep.replace(/\D/g, ""),
                  number: values.address_number,
                  street: values.street,
                  brand: response.extra_info.brand,
                  expire_date: values.expiration,
                  identification: response.extra_info.bin,
                  neighborhood: values.neighbordhood,
                  city: values.city,
                  document_type:
                    _state.type === "legal-entity" ? "CNPJ" : "CPF",
                  token: response.id
                }
              });
            }
          }
        }
      );
    }
  }

  render() {
    return (
      <div className="CreditCard main-holder">
        <Menu />
        <div className="content-holder">
          <div className={`wrapper ${this.state.loading ? "loading" : ""}`}>
            <Link to="/settings#billing-information" className="button-back">
              {"<"} Back to Billing
            </Link>
            <h2 className="title-page">
              {this.state.new_user ? "Add Credit Card" : "Edit Credit Card"}
            </h2>
            {this.state.user_level < 2 && (
              <Formik
                initialValues={{
                  cnpj: "",
                  cpf: "",
                  card_name: "",
                  card_number: "",
                  expiration: "",
                  security_code: "",
                  street: "",
                  address_number: "",
                  city: "",
                  neighbordhood: "",
                  cep: ""
                }}
                validate={values => {
                  let errors = {};
                  if (this.state.type === "legal-entity") {
                    if (!ValidCnpf(values.cnpj)) {
                      errors.cnpj = "Insert a valid CNPJ";
                    }
                  } else {
                    if (!ValidCpf(values.cpf)) {
                      errors.cpf = "Insert a valid CPF";
                    }
                  }

                  if (!values.card_name || values.card_name.trim() === "") {
                    errors.card_name = "Insert the name on the card";
                  }
                  if (iugu.utils) {
                    if (
                      !iugu.utils.validateCreditCardNumber(
                        values.card_number.replace(/\D/g, "")
                      )
                    ) {
                      errors.card_number = "Insert a valid card number";
                    }
                    if (
                      !iugu.utils.validateExpirationString(values.expiration)
                    ) {
                      errors.expiration = "Insert a valid expiration date";
                    }
                  }
                  if (!/^\d{3}$/.test(values.security_code)) {
                    errors.security_code = "Insert a valid security code";
                  }
                  if (!values.street || values.street.trim() === "") {
                    errors.street = "Insert a street name";
                  }
                  if (
                    !values.address_number ||
                    values.address_number.trim() === ""
                  ) {
                    errors.address_number = "Insert a address number";
                  }
                  if (!values.city || values.city.trim() === "") {
                    errors.city = "Insert the city";
                  }
                  if (
                    !values.neighbordhood ||
                    values.neighbordhood.trim() === ""
                  ) {
                    errors.neighbordhood = "Insert the neighbordhood";
                  }
                  if (!/^[0-9]{5}-[0-9]{3}$/.test(values.cep)) {
                    errors.cep = "Insert a valid cep number";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.saveCreditCard(values);
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} id="form-add-card">
                    <SectionHeader
                      title="Identification"
                      description="You identification or enterprise details"
                    />
                    <div className="field">
                      <label>Identification type</label>
                      <div className="SelectBuild">
                        <div className="element">
                          <input
                            type="radio"
                            id="legal"
                            name="select-type"
                            defaultChecked={true}
                            value="1"
                          />
                          <label
                            htmlFor="legal"
                            onClick={() => this.onSelect("legal-entity")}
                          >
                            Legal Entity
                          </label>
                        </div>
                        <div className="element">
                          <input
                            type="radio"
                            id="physical"
                            name="select-type"
                            value="2"
                          />
                          <label
                            htmlFor="physical"
                            onClick={() => this.onSelect("physical-person")}
                          >
                            Physical Person
                          </label>
                        </div>
                      </div>
                    </div>

                    {this.state.type === "legal-entity" ? (
                      <div className="field">
                        <label>CNPJ</label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/
                          ]}
                          guide={false}
                          className={errors.cnpj && touched.cnpj ? "error" : ""}
                          placeholder="e.g. 00.000.000/0000-00"
                          type="text"
                          name="cnpj"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.cnpj}
                        />
                        {errors.cnpj && touched.cnpj && (
                          <div className="error-msg">{errors.cnpj}</div>
                        )}
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="field">
                          <label>CPF</label>
                          <MaskedInput
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/
                            ]}
                            guide={false}
                            className={errors.cpf && touched.cpf ? "error" : ""}
                            placeholder="e.g. 000.000.000-00"
                            type="text"
                            name="cpf"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cpf}
                          />
                          {errors.cpf && touched.cpf && (
                            <div className="error-msg">{errors.cpf}</div>
                          )}
                        </div>
                      </React.Fragment>
                    )}

                    <SectionHeader
                      title="Credit Card"
                      description="Credit Card responsible for payment."
                    />
                    <div className="field">
                      <label>Name on card</label>
                      <input
                        className={
                          errors.card_name && touched.card_name ? "error" : ""
                        }
                        placeholder="Insert the full name in the card"
                        type="text"
                        name="card_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.card_name}
                        data-iugu="full_name"
                      />
                      {errors.card_name && touched.card_name && (
                        <div className="error-msg">{errors.card_name}</div>
                      )}
                    </div>
                    <div className="field">
                      <label>Credit Card Number</label>
                      <MaskedInput
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/
                        ]}
                        guide={false}
                        className={
                          errors.card_number && touched.card_number
                            ? "error"
                            : ""
                        }
                        placeholder="e.g. 0000 0000 0000 0000"
                        type="text"
                        name="card_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.card_number}
                        data-iugu="number"
                      />
                      {errors.card_number && touched.card_number && (
                        <div className="error-msg">{errors.card_number}</div>
                      )}
                    </div>
                    <div className="field" style={{ width: 150 }}>
                      <label>Expiration</label>
                      <MaskedInput
                        mask={[/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                        guide={false}
                        className={
                          errors.expiration && touched.expiration ? "error" : ""
                        }
                        placeholder="MM/YYYY"
                        type="text"
                        name="expiration"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.expiration}
                        data-iugu="expiration"
                      />
                      {errors.expiration && touched.expiration && (
                        <div className="error-msg">{errors.expiration}</div>
                      )}
                    </div>
                    <div className="field" style={{ width: 150 }}>
                      <label>Security Code (CVV)</label>
                      <MaskedInput
                        mask={[/\d/, /\d/, /\d/]}
                        guide={false}
                        className={
                          errors.security_code && touched.security_code
                            ? "error"
                            : ""
                        }
                        placeholder="123"
                        type="text"
                        name="security_code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.security_code}
                        data-iugu="verification_value"
                      />
                      {errors.security_code && touched.security_code && (
                        <div className="error-msg">{errors.security_code}</div>
                      )}
                    </div>

                    <SectionHeader
                      title="Billing Information"
                      description="Geographic localization for invoice matters."
                    />

                    <div className="field">
                      <label>Street Address</label>
                      <input
                        className={
                          errors.street && touched.street ? "error" : ""
                        }
                        type="text"
                        name="street"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.street}
                      />
                      {errors.street && touched.street && (
                        <div className="error-msg">{errors.street}</div>
                      )}
                    </div>
                    <div className="field" style={{ width: 150 }}>
                      <label>Address Number</label>
                      <input
                        className={
                          errors.address_number && touched.address_number
                            ? "error"
                            : ""
                        }
                        type="text"
                        name="address_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address_number}
                      />
                      {errors.address_number && touched.address_number && (
                        <div className="error-msg">{errors.address_number}</div>
                      )}
                    </div>
                    <div className="field">
                      <label>City</label>
                      <input
                        className={errors.city && touched.city ? "error" : ""}
                        type="text"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                      />
                      {errors.city && touched.city && (
                        <div className="error-msg">{errors.city}</div>
                      )}
                    </div>
                    <div className="field">
                      <label>Neighbordhood</label>
                      <input
                        className={
                          errors.neighbordhood && touched.neighbordhood
                            ? "error"
                            : ""
                        }
                        type="text"
                        name="neighbordhood"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.neighbordhood}
                      />
                      {errors.neighbordhood && touched.neighbordhood && (
                        <div className="error-msg">{errors.neighbordhood}</div>
                      )}
                    </div>
                    <div className="field" style={{ width: 150 }}>
                      <label>Zip/Postal Code</label>
                      <MaskedInput
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/
                        ]}
                        guide={false}
                        className={errors.cep && touched.cep ? "error" : ""}
                        type="text"
                        name="cep"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cep}
                      />
                      {errors.cep && touched.cep && (
                        <div className="error-msg">{errors.cep}</div>
                      )}
                    </div>
                    <div className="button-holder">
                      <div className="box-disable">
                        <button
                          className="main-button"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Save Credit Card
                        </button>
                      </div>
                      <span>or</span>
                      <Link
                        to="/settings#billing-information"
                        className="bt-cancel"
                      >
                        Cancel
                      </Link>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user_level: state.user_level,
  id_token: state.id_token,
  user: state.user
});

export default connect(mapStateToProps)(CreditCard);
