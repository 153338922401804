import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { withRouter } from "react-router";
import axios from "axios";
import Global from "../../utils/Global";
import swal from "sweetalert2";

import MaskedInput from "react-text-mask";

import SectionHeader from "../../new-components/SectionHeader";

import "./CreateAccount.css";

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    props.auth.getProfile((err, user) => {
      this.email = user.email;
      this.user_id = user.sub;
    });
  }
  sendForm(values) {
    const tokenStr = this.props.id_token;
    this.setState({ loading: true });
    axios({
      url: `https://${process.env.REACT_APP_API_BASE + "/company"}`,
      method: "post",
      headers: { Authorization: `Bearer ${tokenStr}` },
      data: {
        nm_company: values.nm_account,
        ds_region: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return axios({
            url: `https://${process.env.REACT_APP_API_BASE + "/account"}`,
            method: "post",
            headers: { Authorization: `Bearer ${tokenStr}` },
            data: {
              nm_account: values.nm_account,
              ds_email: this.email,
              id_company: res.data,
              nr_phone: values.nr_phone,
              ds_job_title: values.ds_job_title,
              // auth_id_account: this.user_id,
              // id_role: 1
            },
          });
        } else if (res.status === 202) {
          swal.fire({
            type: "error",
            title: "Oops...",
            text: res.data.message,
          });
          return new Promise((resolve, reject) => {
            resolve(true);
          });
        }
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res.status === 200) {
          swal.fire("Account created", "...", "success").then((result) => {
            if (result.value) {
              this.props.history.push({
                pathname: "/",
              });
            }
          });
        } else if (res.status === 202) {
          swal.fire({
            type: "error",
            title: "Oops...",
            text: res.data.message,
          });
        }
      });
  }
  render() {
    return (
      <div className={`CreateAccount ${this.state.loading ? "loading" : ""}`}>
        <img
          className="logo_open_galaxy"
          src="/static/semantix-open-galaxy.png"
          alt="open galaxy"
        />
        <p className="head-line">
          We’re almost there. One small step for you, one giant leap for your
          data-driven future.
        </p>
        <SectionHeader
          title="Setup your Account"
          description="Give a name for your account and a telephone so our specialist can contact you."
        />
        <Formik
          initialValues={{
            your_name: "",
            nm_account: "",
            nr_phone: "",
            ds_job_title: "",
          }}
          validate={(values) => {
            let errors = {};
            if (!values.your_name) {
              errors.your_name = "Required";
            }
            if (!values.nm_account) {
              errors.nm_account = "Required";
            }
            if (!values.ds_job_title) {
              errors.ds_job_title = "Required";
            }
            if (!values.nr_phone) {
              errors.nr_phone = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.sendForm(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="field">
                <label>Your Name</label>
                <input
                  className={
                    errors.your_name && touched.your_name
                      ? "field_input error"
                      : "field_input"
                  }
                  name="your_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.your_name}
                  type="text"
                  style={{ width: 690 }}
                />

                {errors.your_name && touched.your_name && (
                  <span className="error_msg">{errors.your_name}</span>
                )}
              </div>
              <div className="field">
                <label>Company/Account Name</label>
                {/* <input
                  className={
                    errors.nm_account && touched.nm_account
                      ? "field_input error"
                      : "field_input"
                  }
                  name="nm_account"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nm_account}
                  type="text"
                  style={{ width: 690 }}
                /> */}
                <MaskedInput
                  mask={Array(100)
                    .fill(1)
                    .map((i) => /[a-z]|[A-Z]|\d/)}
                  guide={false}
                  className={
                    errors.nm_account && touched.nm_account
                      ? "field_input error"
                      : "field_input"
                  }
                  name="nm_account"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nm_account}
                  style={{ width: 690 }}
                />
                {errors.nm_account && touched.nm_account && (
                  <span className="error_msg">{errors.nm_account}</span>
                )}
              </div>
              <div className="field">
                <label>Title</label>
                <input
                  className={
                    errors.ds_job_title && touched.ds_job_title
                      ? "field_input error"
                      : "field_input"
                  }
                  name="ds_job_title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ds_job_title}
                  type="text"
                  style={{ width: 690 }}
                />
                {errors.ds_job_title && touched.ds_job_title && (
                  <span className="error_msg">{errors.ds_job_title}</span>
                )}
              </div>
              <div className="field">
                <label>Telephone</label>
                <input
                  className={
                    errors.nr_phone && touched.nr_phone
                      ? "field_input error"
                      : "field_input"
                  }
                  type="tel"
                  name="nr_phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nr_phone}
                  style={{ width: 340 }}
                />

                {errors.nr_phone && touched.nr_phone && (
                  <span className="error_msg">{errors.nr_phone}</span>
                )}
              </div>
              <div>
                <button
                  className="main-button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Finish Setup
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  id_token: state.id_token,
});

export default withRouter(connect(mapStateToProps)(CreateAccount));
