import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import actionAPI from "../../actions/actionAPI";
import Menu from "../../components/Menu/Menu";
import SectionHeader from "../../new-components/SectionHeader";

import "./BillingDate.css";

class BillingDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedOption: 1
    };
  }
  saveSettings() {
    this.setState({
      loading: true
    });
    actionAPI(this.props.id_token, "/billing/card/add", {
      ...this.props.location.state
    })
      .then(_resp => {
        actionAPI(this.props.id_token, "/company/update", {
          id_company: this.props.user.company.id_company,
          closing_date: this.state.selectedOption
        })
          .then(resp => {
            this.setState({
              loading: false
            });
            Swal.fire({
              title: "Credity card billing date successfully added.",
              type: "success",
              confirmButtonText: "Go to billing information"
            }).then(result => {
              if (result.value) {
                this.props.history.push({
                  pathname: "/settings",
                  hash: "#billing-information"
                });
              }
            });
          })
          .catch(err => {
            this.setState({ loading: false });
            Swal.fire({
              type: "error",
              title: "Oops...",
              text: "Something went wrong!"
            });
          });
      })
      .catch(err => {
        this.setState({ loading: false });
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Something went wrong!"
        });
      });
  }
  handleOptionChange(val) {
    this.setState({
      selectedOption: parseInt(val)
    });
  }

  cancel() {
    Swal.fire({
      type: "warning",
      title: "Are you sure?",
      text:
        "If you cancel, you will lose Credit Card and Billing Date details.",
      showCancelButton: true,
      confirmButtonText: "Yes, erase it",
      cancelButtonText: "No, continue",
      confirmButtonColor: "#d33"
    }).then(result => {
      if (result.value) {
        this.props.history.push({
          pathname: "/settings",
          hash: "#billing-information"
        });
      }
    });
  }
  render() {
    const { loading, selectedOption } = this.state;
    return (
      <div className="BillingDate main-holder">
        <Menu />
        <div className="content-holder">
          <div className={`wrapper ${loading ? "loading" : ""}`}>
            <button
              onClick={() => this.cancel()}
              className="button-back bt-link"
            >
              {"<"} Back to Add Credit Card
            </button>
            <h2 className="title-page">Define billing date</h2>
            <SectionHeader
              title="Settings"
              description="Set the best day for monthly Credit Card Payment. This will be valid for any credit card in use."
            />
            <div className="select-date">
              <div className="billing-date">
                <input
                  type="radio"
                  name="billing-date"
                  id="date-01"
                  value={1}
                  checked={selectedOption === 1}
                  onChange={e => this.handleOptionChange(e.target.value)}
                />
                <label htmlFor="date-01">01</label>
              </div>
              <div className="billing-date">
                <input
                  type="radio"
                  name="billing-date"
                  id="date-03"
                  value={3}
                  checked={selectedOption === 3}
                  onChange={e => this.handleOptionChange(e.target.value)}
                />
                <label htmlFor="date-03">03</label>
              </div>
              <div className="billing-date">
                <input
                  type="radio"
                  name="billing-date"
                  id="date-05"
                  value={5}
                  checked={selectedOption === 5}
                  onChange={e => this.handleOptionChange(e.target.value)}
                />
                <label htmlFor="date-05">05</label>
              </div>
              <div className="billing-date">
                <input
                  type="radio"
                  name="billing-date"
                  id="date-10"
                  value={10}
                  checked={selectedOption === 10}
                  onChange={e => this.handleOptionChange(e.target.value)}
                />
                <label htmlFor="date-10">10</label>
              </div>
              <div className="billing-date">
                <input
                  type="radio"
                  name="billing-date"
                  id="date-15"
                  value={15}
                  checked={selectedOption === 15}
                  onChange={e => this.handleOptionChange(e.target.value)}
                />
                <label htmlFor="date-15">15</label>
              </div>
            </div>
            <div className="button-holder">
              <button
                className="main-button"
                onClick={() => {
                  this.saveSettings();
                }}
              >
                Save Settings
              </button>
              <span>or</span>
              <button
                onClick={() => this.cancel()}
                className="bt-cancel bt-link"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  id_token: state.id_token,
  user: state.user
});

export default connect(mapStateToProps)(BillingDate);
