import axios from "axios";
import Global from "../utils/Global";

const actionSimpleAPI = (token, path, data, method = "post") => {
  let dataObj = {
    url: `https://${process.env.REACT_APP_API_BASE + path}`,
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: data ? data : {},
  };
  if (token) {
    return axios(dataObj);
  } else {
    console.error("no id_token found");
  }
};

export default actionSimpleAPI;
