import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { useObservable } from "hooks";
import ReactTooltip from "react-tooltip";
import Menu from "components/Menu/Menu";
import CreateSpaceButton from "components/CreateSpaceButton/CreateSpaceButton";
import IconStatus from "new-components/Icons/Status";
import Table, { TableCol, TableRow } from "new-components/Table";
import actionAPI from "actions/actionAPI";

import "./Environment.css";

export default function Environment(props) {
  const dispatch = useDispatch();
  const store = useSelector((s) => ({ ...s }));
  const [loading, setLoading] = useState(true);
  const spaces = useObservable(store?.environments);
  const userID = useObservable(store.user?.id_account);
  const userLevel = useObservable(store?.user_level) || 3;
  const idToken = useObservable(store?.id_token);
  const getData = async () =>
    await actionAPI(idToken, `/account/${userID}/spaces`, null, "GET");

  useEffect(() => {
    (async () => {
      const response = await getData();
      if (response) {
        dispatch({
          type: "UPDATE_ENVIRONMENTS",
          data: response,
        });
        setLoading(false);
      }
    })();
    return () => setLoading(true);
  }, [idToken, userID]);

  const RenderTable = useMemo(() => {
    return (
      spaces?.length &&
      spaces.map((item) => (
        <TableRow cssClass="EnvironmentItem" key={item.id_space}>
          <TableCol size={10}>
            <div data-tip={item.status}>
              <IconStatus
                className={`icon-status ${item.status}`}
                title={item.status}
              />
              <ReactTooltip />
            </div>
            {item.dt_delete_space && (
              <span className="deleted">
                To be deleted on {item.dt_delete_space}
              </span>
            )}
          </TableCol>
          <TableCol size={35}>
            <Link
              className="environment-name"
              to={{
                pathname: `/environment/${item.id_space}`,
                state: {
                  ...item,
                },
              }}
            >
              {item.nm_space}
            </Link>
            <span className="environment-stage">
              {item.pack.nm_stage} - {item?.cloud_region?.nm_cloud_provider}
            </span>
          </TableCol>
          <TableCol size={40}>
            <div className="services">
              {item.services.map((tool) => (
                <div className="service" key={tool.id_tool}>
                  <div className="service-text">
                    <span className="qtd">{tool.ds_tool}x</span>
                    <span className="version">{tool.ds_version}</span>
                  </div>
                  <div className="service-img">
                    <img src={tool.ds_logo} alt={tool.ds_version} />
                  </div>
                </div>
              ))}
            </div>
          </TableCol>
          <TableCol size={15}>
            <Moment format="DD/MM/YYYY">
              {item.dt_create
                .split(" ")[0]
                .split("-")
                .join(",")}
            </Moment>
          </TableCol>
        </TableRow>
      ))
    );
  }, [spaces]);

  return (
    <div className="Environment main-holder">
      <Menu />
      <div className="content-holder">
        <div className={`wrapper ${loading ? "loading" : ""}`}>
          <h2 className="title-page">Spaces</h2>
          <div className="create-spaces_wrap">
            <CreateSpaceButton />
          </div>
          <Table
            header={[
              ["Status", 10],
              ["Name", 35],
              ["Services", 40],
              ["Created at", 15],
            ]}
          >
            {spaces.length ? (
              RenderTable
            ) : (
              <div className="no-item">
                <span className="title">You have no spaces yet.</span>
                <span className="text">
                  Click on Create space to add your first.
                </span>
              </div>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
}
