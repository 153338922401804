import React from 'react';

import Menu from "../../components/Menu/Menu";
import './Page404.css';

const Page404 = () => (
  <div className="main-holder">
    <Menu />
    <div className="content-holder">
      <div className="wrapper">
        <div className="notfound">
          <img src="/static/image-404.png" alt="Error Page"/>
          <div className="text-holder">
            <h2 className="title-page">Oops! <br /><br /> We did not find what you were looking for!</h2>
            <p>What could have happened?</p>
            <ul>
              <li>It may be that this page no longer exists;</li>
              <li>The address you looked for may be wrong.</li>
            </ul>
            <p>You can navigate using the menu</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Page404;