import React from "react";
import Container from "../../Box/Container";

const DeleteSpace = ({ deleteEnvironment, stage, askForDeleting }) => (
  <div className="DeleteSpace">
    <div className="header-services">
      <h3 className="title">Danger Zone</h3>
    </div>
    <Container>
      <div className="DangerZone">
        {(stage === "Enterprise") ? (
          <React.Fragment>
            <div className="message">
              <span className="title">Ask for Deleting this Space</span>
              <span className="text">
                You can ask our team to delete this Space. Our specialists will
                contact you.
              </span>
            </div>
            <button className="down-button" onClick={() => askForDeleting()}>
              Ask for Deleting
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="message">
              <span className="title">Delete this space</span>
              <span className="text">
                Once you delete a space, there is no going back. Please be
                certain.
              </span>
            </div>
            <button className="down-button" onClick={() => deleteEnvironment()}>
              Delete space
            </button>
          </React.Fragment>
        )}
      </div>
    </Container>
  </div>
);

export default DeleteSpace;
