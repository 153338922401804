import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import swal from "sweetalert2";
import actionApi from "actions/actionAPI";
import Table, { TableCol, TableRow } from "new-components/Table";
import * as utils from "./utils";

export default function Security(props) {
  const { idEnvironment } = props;
  const idToken = useSelector(s => s.id_token);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reoladData, setReloadData] = useState(false);

  const getList = async () =>
    await actionApi(idToken, `/whitelist/${idEnvironment}`, null, "GET");

  useEffect(() => {
    (async () => {
      const response = await getList();
      if (response) {
        setData(response);
        setLoading(false);
        return;
      }
      setLoading(false);
    })();

    return () => {
      setLoading(true);
    };
  }, [idToken, reoladData]);

  const handlRevokeAccess = id => {
    swal.fire(utils.alert.revoke).then(result => {
      if (result.value) {
        setLoading(true);
        actionApi(idToken, `/whitelist/${idEnvironment}/${id}`, null, "DELETE")
          .then(() => setReloadData(prev => !prev))
          .catch(() => setLoading(false));
      }
    });
  };

  const submitWhitelist = () => (values, { setSubmitting, resetForm }) => {
    const { clean: c } = utils;
    setLoading(true);
    const body = {
      ds_ip: `${c(values.ip_1)}.${c(values.ip_2)}.${c(values.ip_3)}.${c(
        values.ip_4
      )}/${c(values.ip_prefix)}`,
      ds_whitelist: values.description
    };
    actionApi(idToken, `/whitelist/${idEnvironment}`, body, "POST")
      .then(() => {
        swal.fire(utils.alert.submit).then(() => {
          setReloadData(prev => !prev);
          setSubmitting(false);
          resetForm(utils.initialValue);
        });
      })
      .catch(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const mapContent = item => (
    <TableRow key={item.id_whitelist}>
      <TableCol size={20}>{item.ds_ip}</TableCol>
      <TableCol size={60}>{item.ds_whitelist}</TableCol>
      <TableCol size={20}>
        <button
          onClick={() => {
            handlRevokeAccess(item.id_whitelist);
          }}
          className="remove"
        >
          revoke access
        </button>
      </TableCol>
    </TableRow>
  );

  const TableContent = useMemo(() => data.map(mapContent), [data]);

  return (
    <div className={`EnvDetailPage ${loading ? "loading" : ""}`}>
      <h2 className="title-page">IP whitelist</h2>
      <Table
        header={[
          ["IP", 20],
          ["Description", 80]
        ]}
      >
        {data.length ? (
          TableContent
        ) : (
          <TableRow>
            <div className="no-content">
              <span className="title">
                This account does not have whitelisted IP addresses yet.
              </span>
            </div>
          </TableRow>
        )}
      </Table>
      <div className="AddIp">
        <h3>Whitelist IP addresses</h3>
        <Formik
          initialValues={utils.initialValue}
          onSubmit={submitWhitelist()}
          validate={utils.validateForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={e => e.preventDefault()} noValidate>
              <div className="field">
                <label>IP</label>
                <MaskedInput
                  mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
                  showMask={false}
                  name="ip_1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ip_1}
                  className={errors.ip_1 && touched.ip_1 ? "error" : ""}
                  style={{ width: 50 }}
                />
                <span className="period">.</span>
                <MaskedInput
                  mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
                  showMask={false}
                  name="ip_2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ip_2}
                  className={errors.ip_2 && touched.ip_2 ? "error" : ""}
                  style={{ width: 50 }}
                />
                <span className="period">.</span>
                <MaskedInput
                  mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
                  showMask={false}
                  name="ip_3"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ip_3}
                  className={errors.ip_3 && touched.ip_3 ? "error" : ""}
                  style={{ width: 50 }}
                />
                <span className="period">.</span>
                <MaskedInput
                  mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
                  showMask={false}
                  name="ip_4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ip_4}
                  className={errors.ip_4 && touched.ip_4 ? "error" : ""}
                  style={{ width: 50 }}
                />
                <span className="period">/</span>
                <MaskedInput
                  mask={[/[0-9]/, /[0-9]/]}
                  showMask={false}
                  name="ip_prefix"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ip_prefix}
                  className={
                    errors.ip_prefix && touched.ip_prefix ? "error" : ""
                  }
                  style={{ width: 50 }}
                />
                {errors.ip_1 && touched.ip_1 && (
                  <span className="error_msg">{errors.ip_1}</span>
                )}
                {errors.ip_2 && touched.ip_2 && (
                  <span className="error_msg">{errors.ip_2}</span>
                )}
                {errors.ip_3 && touched.ip_3 && (
                  <span className="error_msg">{errors.ip_3}</span>
                )}
                {errors.ip_4 && touched.ip_4 && (
                  <span className="error_msg">{errors.ip_4}</span>
                )}
                {errors.ip_prefix && touched.ip_prefix && (
                  <span className="error_msg">{errors.ip_prefix}</span>
                )}
              </div>
              <div className="field">
                <label>Description (optional)</label>
                <input
                  className="field_input"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  type="text"
                  style={{ width: 400 }}
                />
              </div>
              <div>
                <button
                  className="main-button"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  whitelist IP
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
