import React, { Component } from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import ValidCpf from "../../../utils/validar-cpf";
import ValidCnpf from "../../../utils/validar-cnpj";
import ValidData from "../../../utils/validar-data";
import "./Input.css";

const validation = {
  required: val => !(!val || val.trim() === ""),
  email: val => {
    // eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(val).toLowerCase());
  },
  password: (val, state) => val === state.equalVal,
  cardNumber: val => {
    let clean = val.replace(/_/g, "").replace(/ /g, "");
    if (window.Iugu) {
      return window.Iugu.utils.validateCreditCardNumber(clean);
    }
    return false;
  },
  expiration: val => {
    if (window.Iugu) {
      return window.Iugu.utils.validateExpirationString(val);
    }
    return false;
  },
  securityCode: val => {
    return /^\d{3}$/.test(val);
  },
  validCep: val => {
    return /^[0-9]{5}-[0-9]{3}$/.test(val);
  },
  validCpf: val => {
    return ValidCpf(val);
  },
  validCnpf: val => {
    return ValidCnpf(val.replace(/\D/g, ""));
  },
  validData: val => {
    return ValidData(val);
  }
};

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      touched: false,
      equalVal: props.equalVal
    };

    this.params = {
      placeholder: props.placeholder,
      name: props.name,
      onBlur: e => this.blurCheck(e.target.value),
      onChange: e => this.setValue(e.target.value),
      "data-iugu": props.dataIugu
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      equalVal: nextProps.equalVal
    });
  }
  setValue(val) {
    let temp = this.props.validation
      .map(func => {
        return validation[func](val, this.state);
      })
      .filter(item => !item);
    if (temp.length > 0) {
      this.setState(
        {
          valid: false
        },
        () => this.pushValue(val)
      );
    } else {
      this.setState(
        {
          valid: true
        },
        () => this.pushValue(val)
      );
    }
  }
  pushValue(val) {
    this.props.setValue({
      value: val,
      valid: this.state.valid
    });
  }
  blurCheck(val) {
    this.setValue(val);
    this.setState({ touched: true });
  }
  render() {
    return (
      <div className={`Input ${this.props.cssClass}`}>
        {this.props.fieldType === "number" && (
          <MaskedInput
            mask={this.props.mask}
            className={!this.state.valid && this.state.touched ? `error` : ""}
            guide={false}
            {...this.params}
            value={this.props.defaultValue}
          />
        )}
        {this.props.fieldType === "text" && (
          <input
            className={!this.state.valid && this.state.touched ? `error` : ""}
            type={this.props.type}
            defaultValue={this.props.defaultValue}
            disabled={this.props.disabled}
            maxLength={this.props.maxlength}
            {...this.params}
          />
        )}
        {this.props.fieldType === "select" && (
          <select
            {...this.params}
            className={!this.state.valid && this.state.touched ? `error` : ""}
          >
            <option value="">Select...</option>
            {this.props.data.map((item, index) => (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            ))}
          </select>
        )}
        {!this.state.valid && this.state.touched ? (
          <span className="error-message">{this.props.message}</span>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Input.propTypes = {
  validation: PropTypes.array,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  fieldType: PropTypes.string,
  data: PropTypes.array,
  cssClass: PropTypes.string,
  maxlength: PropTypes.number,
  mask: PropTypes.any
};

Input.defaultProps = {
  validation: [],
  setValue: e => e,
  disabled: false,
  fieldType: "text",
  data: [],
  cssClass: "",
  mask: []
};

export default Input;
