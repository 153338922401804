import React, { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useObservable } from "hooks";
import Table, { TableCol, TableRow } from "new-components/Table";
import Container from "new-components/Box/Container";
import AddTeam from "new-components/Settings/AddTeam";
import AddUser from "new-components/Settings/AddUser";
import swal from "sweetalert2";
import * as utils from "./utils";

import actionAPI from "actions/actionAPI";

export default function Acces(props) {
  const store = useSelector((s) => s);
  const idToken = useObservable(store?.id_token);
  const [addedUsers, setAddedUsers] = useState([]);
  const [users, setUsers] = useState([]);

  const getEnvAccounts = async () =>
    await actionAPI(
      idToken,
      `/space/${props.idEnvironment}/accounts`,
      null,
      "get"
    );

  const getCompanyAccounts = async () =>
    await actionAPI(idToken, "/company/accounts", null, "get");

  useEffect(() => {
    (async () => {
      if (idToken) {
        const envAccounts = await getEnvAccounts();
        const companyAccounts = await getCompanyAccounts();
        setAddedUsers(envAccounts);
        setUsers(companyAccounts);
      }
    })();
    const unMount = () => {
      setUsers([]);
      setAddedUsers([]);
    };
    return unMount;
  }, [idToken]);

  const handleAddUser = (user) => {
    let userExists = addedUsers.find((it) => it.id_account === user.id_account);
    if (!userExists) {
      let url = `/space/${props.idEnvironment}/${user.id_account}`;
      actionAPI(idToken, url).then(() => {
        setAddedUsers((prev) => [...prev, user]);
      });
    }
  };

  const handleRemoveUser = (user) => {
    swal.fire(utils.alert.removerUser).then((result) => {
      if (result.value) {
        let url = `/space/${props.idEnvironment}/${user.id_account}`;
        actionAPI(idToken, url, null, "DELETE")
          .then((response) => {
            swal.fire(response.message, "", "success").then((res) => {
              setAddedUsers((prev) =>
                prev.filter((it) => it.id_account !== user.id_account)
              );
            });
          })
          .catch((error) => {
            const { response } = error;
            if (response?.data) {
              swal.fire(response.data?.message, "", "error");
            }
          });
      }
    });
  };

  const RenderAddedUsersRow = useMemo(
    () =>
      !!addedUsers.length ? (
        <>
          {addedUsers?.map((item) => (
            <TableRow cssClass="space" key={item.id_account}>
              <TableCol size={30}>
                <span>{item.nm_account}</span>
              </TableCol>
              <TableCol size={30}>
                <span>{item.ds_email}</span>
              </TableCol>
              <TableCol size={25}>
                <span>{item?.nm_role}</span>
              </TableCol>
              <TableCol size={15}>
                <button
                  className="remove"
                  onClick={() => handleRemoveUser(item)}
                >
                  Revoke Access
                </button>
              </TableCol>
            </TableRow>
          ))}
          <TableRow cssClass="table-footer">
            <AddUser
              users={users}
              addUser={(user) => handleAddUser(user)}
              description="Add users to this space"
            />
          </TableRow>
        </>
      ) : (
        <TableRow>
          <div className="no-content">
            <span className="title">
              This team does not have any user associated yet.
            </span>
            <span className="text">
              Use the field below to add users to this team.
            </span>
          </div>
        </TableRow>
      ),
    [addedUsers, users]
  );

  return (
    <div className="Access">
      <h2 className="title-page">Users</h2>
      <Container cssClass="Table-items">
        <Table
          header={[
            ["Name", 30],
            ["Email", 30],
            ["Role", 40],
          ]}
        >
          {RenderAddedUsersRow}
        </Table>
      </Container>
    </div>
  );
}
