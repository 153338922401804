import React from 'react';
import PropTypes from 'prop-types';
import './Card.css';

export const CardList = (props) => (
  <div className="CardList">
    {props.children}
  </div>
);

CardList.propTypes = {
  children: PropTypes.node
}

const Card = (props) => (
  <div className={`Card ${props.variant}`}>
    {props.children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node,
  variant: ''
}

export default Card;
