import React from 'react';
import PropTypes from 'prop-types';
import './Container.css'

const Container = (props) => (
    <div className={`Container ${props.cssClass}`}>
        {props.children}
    </div>
);

Container.propTypes = {
    cssClass: PropTypes.string,
    children: PropTypes.node
}

export default Container;