import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import swal from "sweetalert2";
import Menu from "../../components/Menu/Menu";
import Table, { TableCol, TableRow } from "../../new-components/Table";
import Container from "../../new-components/Box/Container";

import axios from "axios";

import AddField from "../../new-components/Settings/AddField";
import AddUser from "../../new-components/Settings/AddUser";

import actionAPI from "../../actions/actionAPI";

class TeamSpaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user_data: {},
      user_id: "",
      environments: [],
      added_environments: [],
      users: [],
      added_users: [],
      user_level: props.user_level
    };
  }
  componentDidMount() {
    this.props.getPageData(this.props.id_token);
    this.props.getUsers(this.props.id_token);
    this.setState({
      loading: true
    });

    axios
      .all([
        actionAPI(this.props.id_token, "/team/details", {
          id_team: this.props.match.params.id
        }),
        actionAPI(this.props.id_token, "/team/environment/list", {
          id_team: this.props.match.params.id
        })
      ])
      .then(
        axios.spread((respTeamDetails, respTeamEnv) => {
          this.setState({
            user_data: {
              name: respTeamDetails.name
            },
            added_users: respTeamDetails.accounts,
            added_environments: respTeamEnv,
            loading: false
          });
        })
      );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.user_id !== this.state.user_id) {
      this.setState(
        {
          user_id: nextProps.user_id
        },
        () => {
          this.props.getPageData({
            id_user: this.state.user_id
          });
        }
      );
    }
    this.setState({
      environments: nextProps.environments,
      users: nextProps.users,
      user_level: nextProps.user_level
    });
  }
  addEnvironment(env) {
    let val = this.state.added_environments.find(
      item => item.name === env.name
    );
    if (!val) {
      this.setState({
        loading: true
      });
      actionAPI(this.props.id_token, "/environment/team/permission", {
        id_environment: env.id_environment,
        id_team: this.props.match.params.id
      }).then(() => {
        this.setState({
          added_environments: [...this.state.added_environments, env],
          loading: false
        });
      });
    }
  }
  addUser(user) {
    let val = this.state.added_users.find(item => item.name === user.name);
    if (!val) {
      this.setState({
        loading: true
      });
      actionAPI(this.props.id_token, "/team/account/link", {
        id_team: this.props.match.params.id,
        id_account: user.id_account
      }).then(() => {
        this.setState({
          added_users: [...this.state.added_users, user],
          loading: false
        });
      });
    }
  }
  removeTeam() {
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!"
      })
      .then(result => {
        if (result.value) {
          actionAPI(this.props.id_token, "/team/delete", {
            id_team: this.props.match.params.id
          }).then(() => {
            swal.fire("Team deleted!", "", "success").then(() => {
              this.props.history.push({
                pathname: "/settings/",
                hash: "#teams"
              });
            });
          });
        }
      });
  }
  removeSpace(space) {
    swal
      .fire({
        title: "Are you sure?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!"
      })
      .then(result => {
        if (result.value) {
          this.setState({ loading: true });
          actionAPI(this.props.id_token, "/environment/team/revoke", {
            id_environment: space.id_environment,
            id_team: this.props.match.params.id
          }).then(resp => {
            swal.fire(resp.message, "", "success");
            this.setState({
              added_environments: this.state.added_environments.filter(
                it => it.id_environment !== space.id_environment
              ),
              loading: false
            });
          });
        }
      });
  }
  removeUser(user) {
    swal
      .fire({
        title: "Are you sure?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!"
      })
      .then(result => {
        if (result.value) {
          this.setState({ loading: true });
          actionAPI(this.props.id_token, "/team/account/unlink", {
            id_team: this.props.match.params.id,
            id_account: user.id_account
          }).then(resp => {
            swal.fire(resp.message, "", "success");
            this.setState({
              added_users: this.state.added_users.filter(
                it => it.id_account !== user.id_account
              ),
              loading: false
            });
          });
        }
      });
  }
  render() {
    return (
      <div className="UserSpaces main-holder">
        <Menu />
        <div className="content-holder">
          <div className={`wrapper ${this.state.loading ? "loading" : ""}`}>
            <Link to="/settings#teams" className="button-back">
              {"<"} Back to teams
            </Link>
            <div className="head-user">
              <div className="block-user-name">
                <h2 className="title-page">{this.state.user_data.name}</h2>
              </div>
              <Link
                to={{
                  pathname: `/edit-team/${this.props.match.params.id}`,
                  state: { ...this.state.user_data }
                }}
                className="main-button"
              >
                Edit team
              </Link>
            </div>
            {this.state.user_level < 2 && (
              <div>
                <h2 className="title-page">Spaces</h2>
                <Container cssClass="Table-items">
                  <Table
                    header={[
                      ["Name", 50],
                      ["Services", 50]
                    ]}
                  >
                    {this.state.added_environments.map(item => (
                      <TableRow cssClass="space" key={item.id_environment}>
                        <TableCol size={50}>
                          <div className="space-name">
                            <Link to="/">{item.name}</Link>
                            {item.stage && <span>{item.stage.name}</span>}
                          </div>
                        </TableCol>
                        <TableCol size={35}>
                          <div className="services">
                            {item.services[0].tools.map(it => (
                              <div className="service" key={it.id_tool}>
                                <div className="service-text">
                                  <span className="qtd">1x</span>
                                  <span className="version">{it.version}</span>
                                </div>
                                <div className="service-img">
                                  <img src={it.logo} alt={it.version} />
                                </div>
                              </div>
                            ))}
                          </div>
                        </TableCol>
                        <TableCol size={15}>
                          <button
                            className="remove"
                            onClick={() => this.removeSpace(item)}
                          >
                            Revoke Access
                          </button>
                        </TableCol>
                      </TableRow>
                    ))}
                    {this.state.added_environments.length === 0 ? (
                      <TableRow>
                        <div className="no-content">
                          <span className="title">
                            This user does not have access to spaces yet.
                          </span>
                          <span className="text">
                            Start adding spaces for this user below
                          </span>
                        </div>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    <TableRow cssClass="table-footer">
                      <AddField
                        environments={this.state.environments}
                        addSpace={space => this.addEnvironment(space)}
                      />
                    </TableRow>
                  </Table>
                </Container>
                <h2 className="title-page">Users</h2>
                <Container cssClass="Table-items">
                  <Table
                    header={[
                      ["Name", 30],
                      ["Email", 30],
                      ["Role", 40]
                    ]}
                  >
                    {this.state.added_users.map(item => (
                      <TableRow cssClass="space" key={item.id_account}>
                        <TableCol size={30}>
                          <span>{item.name}</span>
                        </TableCol>
                        <TableCol size={30}>
                          <span>{item.email}</span>
                        </TableCol>
                        <TableCol size={25}>
                          <span>{item.role.name}</span>
                        </TableCol>
                        <TableCol size={15}>
                          <button
                            className="remove"
                            onClick={() => this.removeUser(item)}
                          >
                            Revoke Access
                          </button>
                        </TableCol>
                      </TableRow>
                    ))}
                    {this.state.added_users.length === 0 ? (
                      <TableRow>
                        <div className="no-content">
                          <span className="title">
                            This team does not have any user associated yet.
                          </span>
                          <span className="text">
                            Use the field below to add users to this team.
                          </span>
                        </div>
                      </TableRow>
                    ) : (
                      ""
                    )}
                    <TableRow cssClass="table-footer">
                      <AddUser
                        users={this.state.users}
                        addUser={user => this.addUser(user)}
                      />
                    </TableRow>
                  </Table>
                </Container>
                <h2 className="title-page">Danger Zone</h2>
                <div className="danger-zone">
                  <div className="text">
                    <span className="title">
                      Remove {this.state.user_data.name} from this account
                    </span>
                    <span>
                      Users inserted on this team will lose access to all spaces
                      associated with it.
                    </span>
                  </div>
                  <button
                    className="main-button"
                    onClick={() => this.removeTeam()}
                  >
                    Remove team
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  environments: state.environments,
  users: state.users,
  user_id: state.user_id,
  user_level: state.user_level,
  id_token: state.id_token
});

const mapDispatchToProps = dispatch => {
  return {
    getPageData: token => {
      actionAPI(token, "/account/spaces", null, "get").then(resp => {
        dispatch({
          type: "UPDATE_ENVIRONMENTS",
          data: resp
        });
      });
    },
    getUsers: token => {
      actionAPI(token, "/company/accounts", null, "get").then(resp => {
        dispatch({
          type: "ADD_USERS",
          data: resp
        });
      });
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamSpaces)
);
