import React from "react";
import { Link } from "react-router-dom";
import "./TermsOfService.css";

const TermsOfService = () => (
  <div className="main-holder">
    <div className="content-holder">
      <div className="wrapper terms-services">
        <h2 className="title-page">
          Termos e condições gerais de uso do OpenGalaxy
        </h2>
        <p>
          Olá! Seja bem-vindo ao OpenGalaxy! Antes de você começar a utilizar os
          nossos serviços, é muito importante que você leia, entenda e concorde
          com os nossos Termos e Condições Gerais de Uso. Este documento
          descreve os seus direitos e deveres ao utilizar os nossos serviços,
          portanto recomendamos que você leia cuidadosamente.
        </p>
        <p>
          Estes Termos e Condições Gerais de Uso (“Termos”) constituem um
          contrato entre a{" "}
          <strong>SEMANTIX TECNOLOGIA EM SISTEMA DE INFORMAÇÃO S.A.</strong>,
          pessoa jurídica de direito privado, inscrita no CNPJ sob o nº.
          09.162.524/0001-53, com sede na Av. Eusébio Matoso, 1375 - Pinheiros -
          São Paulo/SP - Brasil (“Semantix” ou “nós”) e você ou a empresa que
          você representa (“Usuário”), e vinculam as partes em todas as
          obrigações e direitos dele decorrentes. Os Termos aplicam-se à
          utilização dos serviços de hospedagem e suporte oferecidos pela
          Semantix e estão de acordo com a Lei nº 12.965/14 (“Marco Civil da
          Internet”), o Decreto nº 8.771/16 (“Decreto Regulamentador”) e demais
          diplomas normativos brasileiros.
        </p>
        <h3>Definições</h3>
        <p>
          Para os fins destes Termos e Condições Gerais de Uso, os termos abaixo
          terão os seguintes significados:
        </p>
        <p>
          <strong>Conteúdo de Terceiro:</strong> significa o software, dados,
          aplicações, texto, áudio, vídeo ou imagens disponibilizados por
          qualquer terceiro na plataforma OpenGalaxy ou em conjunto com os
          Serviços.
        </p>
        <p>
          <strong>Acordo de Nível de Serviço:</strong> é o documento, ou
          cláusula contratual, em que a Semantix assume o compromisso de
          garantir determinados níveis de qualidade, relativos aos Serviços, e
          eventuais compensações quando os níveis de qualidade não forem
          atingidos.{" "}
        </p>
        <p>
          <strong>Data de Rescisão:</strong> é a data efetiva da rescisão que,
          de acordo com o disposto na Cláusula 12, será determinada por
          notificação enviada por uma Parte à outra.
        </p>
        <p>
          <strong>Data de Vigência:</strong> é a data em que estes Termos passam
          a ter vigência para o Usuário, ou seja, na data do seu aceite.
        </p>
        <p>
          <strong>Impostos Indiretos:</strong> são os impostos e taxas
          eventualmente aplicáveis aos Serviços, incluindo, mas não se limitando
          a, Imposto sobre o Valor Agregado (IVA), imposto sobre o serviço,
          imposto sobre vendas, imposto sobre renda bruta.
        </p>
        <p>
          <strong>Informações Confidenciais.</strong> As Informações
          Confidenciais são (a) informações de caráter não público relacionadas
          à Semantix ou a tecnologia dos terceiros parceiros, nossos planos de
          negócios, atividades comerciais e promocionais, finanças e outros
          assuntos comerciais; (b) informações de terceiros, as quais a Semantix
          é obrigada a manter em sigilo; e (c) informações referentes à
          natureza, conteúdo e existência de quaisquer discussões ou negociações
          entre as Partes. As Informações Confidenciais não incluem as
          informações que: (i) sejam de domínio público ou estejam disponíveis
          publicamente antes do recebimento por parte do Usuário; (ii)
          comprovadamente eram de conhecimento do Usuário no momento em que as
          recebeu; (iii) sejam recebidas de terceiro, que não as adquiriu ou
          revelou por conduta ilícita ou prejudicial; ou (iv) comprovadamente
          foram desenvolvidas de modo independente pelo Usuário.
        </p>
        <p>
          <strong>Informações de Conta (Informações Pessoais):</strong> são as
          informações fornecidas pelo Usuário no momento de criação ou
          administração de sua conta na plataforma OpenGalaxy. Estas informações
          estão protegidas pela Política de Privacidade.
        </p>
        <p>
          <strong>Parte(s):</strong> é a palavra utilizada para mencionar tanto
          a Semantix quanto o Usuário nestes Termos.
        </p>
        <p>
          <strong>Política de Privacidade:</strong> é o documento onde estão os
          termos de confidencialidade, proteção e uso dos seus dados e
          Informações de Conta. Disponível em:
          <Link to="/privacy-policy">politicas de privacidade</Link>
        </p>
        <p>
          <strong>Seu Conteúdo:</strong> é o conteúdo que o Usuário transfere
          para nós para processamento, armazenamento ou hospedagem por meio dos
          Serviços relacionados à sua conta e quaisquer resultados
          computacionais obtidos por meio dos Serviços.
        </p>
        <p>
          <strong>Serviço(s):</strong> são os nossos serviços disponibilizados,
          incluindo os conteúdos, marcas e quaisquer outros produtos fornecidos
          pela Semantix de acordo com estes Termos. Os Serviços não incluem os
          Conteúdos de Terceiros.
        </p>
        <h3>1. Capacidade das Partes e Aceite dos Termos</h3>
        <p>
          <strong>1.1.</strong> Para utilizar os Serviços, é imprescindível que
          o Usuário aceite os Termos, clicando na opção “Eu Aceito” na caixa de
          seleção que é apresentada junto deste documento. Caso o Usuário não
          aceite os Termos, os Serviços não poderão ser utilizados.
        </p>
        <p>
          <strong>1.2.</strong> Ao aceitar os Termos e utilizar os Serviços, o
          Usuário também aceita os Termos de Uso dos nossos Terceiros Parceiros,
          que estão disponíveis para consulta em{" "}
          <a href="https://www.cloudera.com/products/open-source.html">
            https://www.cloudera.com/products/open-source.html
          </a>{" "}
          (Cloudera) ou{" "}
          <a href="https://www.elastic.co/gdpr">https://www.elastic.co/gdpr</a>{" "}
          (Elastic.co).
        </p>
        <p>
          <strong>1.3.</strong> O Usuário declara que tem mais de 18 (dezoito)
          anos e está legalmente apto a celebrar contratos, inclusive com
          poderes suficientes para representar a sua empresa, se for o caso.
        </p>
        <h3>2. Criação de Conta e Uso dos Serviços</h3>
        <p>
          <strong>2.1.</strong> Para ter acesso aos Serviços, o Usuário deverá
          criar uma conta na plataforma OpenGalaxy e será responsável por nos
          fornecer informações de registro e de pagamento precisas, verdadeiras,
          completas e atualizadas.
        </p>
        <p>
          <strong>2.2.</strong> O uso dos Serviços deve estar de acordo e não
          infringir nenhuma lei, regulamento ou ato normativo relativo, mas não
          se limitando, a Propriedade Intelectual e proteção de dados.
        </p>
        <p>
          <strong>2.3.</strong> O Usuário concorda em não acessar e nem tentar
          acessar a interface administrativa dos Serviços e nem se envolver em
          nenhuma atividade que interfira ou cause interrupção dos Serviços, os
          servidores e/ou redes às quais eles estejam conectados.
        </p>
        <h3>3. Responsabilidades do Usuário</h3>
        <p>
          <strong>3.1.</strong> O Usuário é exclusivamente responsável pela
          segurança da sua senha e pela integridade da sua conta, eximindo a
          Semantix de qualquer responsabilidade por eventuais acessos não
          autorizados.
        </p>
        <p>
          <strong>3.2.</strong> O Usuário é responsável pelo acesso e pelas
          atividades desenvolvidas na sua conta, independente se estas foram, ou
          não, autorizadas ou desenvolvidas pelo Usuário.
        </p>
        <p>
          <strong>3.3.</strong> O Usuário é responsável por garantir que o Seu
          Conteúdo e o uso dos Serviços não infrinjam o disposto nestes Termos
          ou em qualquer legislação aplicável. Bem como é responsável por
          realizar backups e tomar as medidas adequadas e razoáveis para
          proteger a sua conta e Seu Conteúdo.
        </p>
        <h3>4. Conteúdo de Terceiros</h3>
        <p>
          <strong>4.1.</strong> O Usuário poderá utilizar o Conteúdo de
          Terceiros a seu exclusivo critério, conforme sua necessidade e/ou
          conveniência. De acordo com o disposto na Cláusula 1.2, cada Conteúdo
          de Terceiros será regido pelos seus próprios Termos de Uso.
        </p>
        <h3>5. Propriedade Intelectual</h3>
        <p>
          <strong>5.1.</strong> A Semantix declara que não possui nenhum direito
          de propriedade intelectual, patrimonial ou de qualquer outra espécie
          sobre o Seu Conteúdo. O Usuário declara e concorda que é o único
          responsável pelo Seu Conteúdo.
        </p>
        <p>
          <strong>5.2.</strong> O Usuário declara e concorda que a Semantix
          detém todos os direitos, de qualquer espécie, referentes à plataforma
          OpenGalaxy e aos Serviços, incluindo todos os direitos de propriedade
          intelectual relacionados.
        </p>
        <p>
          <strong>5.3.</strong> Por meio da aceitação destes Termos, a Semantix
          concede ao Usuário uma licença limitada, revogável, não exclusiva,
          intransferível e não sublicenciável para acessar e utilizar os
          Serviços de acordo com o aqui estipulado.
        </p>
        <p>
          <strong>5.4.</strong> O Usuário declara e concorda que não utilizará
          os Serviços de qualquer modo em desacordo com os presentes Termos e
          não tentará (i) copiar, modificar, distribuir, alterar, adulterar ou
          de qualquer outro modo criar trabalhos derivados dos Serviços e dos
          Conteúdos de Terceiros (salvo na hipótese em que o Conteúdo de
          Terceiro for licenciado sob licença de código aberto); (ii) fazer
          engenharia reversa, desmontar ou decompilar os Serviços ou aplicar
          qualquer outro processo ou procedimento para obter o código-fonte da
          plataforma OpenGalaxy; (iii) acessar ou utilizar os Serviços de forma
          a evitar incorrer em taxas ou exceder as quotas limites de uso, ou
          (iv) revender ou sublicenciar os Serviços a terceiros.
        </p>
        <p>
          <strong>5.5.</strong> Alguns Conteúdos de Terceiros poderão ser
          fornecidos sob licença apartada (licença de código aberto, por
          exemplo). Em caso de conflito entre estes Termos e as licenças
          apartadas mencionadas, estas últimas prevalecerão.
        </p>
        <h3>6. Segurança e Proteção de Dados</h3>
        <p>
          <strong>6.1.</strong> A Semantix se compromete a implementar medidas
          razoáveis e adequadas para auxiliar o Usuário a proteger o Seu
          Conteúdo contra perda acidental, invasão, acesso não autorizado e
          vazamento de informações.
        </p>
        <p>
          <strong>6.2. Acesso ao Seu Conteúdo.</strong> A Semantix não terá
          acesso ao Seu Conteúdo. O Seu Conteúdo será protegido por uma chave
          criptografada, particular e única que permitirá somente o seu acesso.
        </p>
        <p>
          <strong>6.3. Acesso às Informações de Conta.</strong> As Informações
          de Conta (Informações Pessoais) do Usuário são protegidas pela
          Política de Privacidade. A Política de Privacidade não se aplica ao
          Seu Conteúdo.
        </p>
        <h3>7. Alterações e Encerramento dos Serviços</h3>
        <p>
          <strong>7.1.</strong> A Semantix tem o direito de alterar ou encerrar
          os Serviços oferecidos, ou alterar ou remover funcionalidades dos
          Serviços a nosso exclusivo critério. A Semantix notificará o Usuário
          sobre qualquer alteração substancial ou encerramento dos Serviços com
          um prazo razoável de antecedência.
        </p>
        <p>
          <strong>7.2.</strong> Na hipótese de encerramento dos Serviços, a
          Semantix se obriga a disponibilizar os dados do Seu Conteúdo em
          formato padrão de mercado, pelo prazo de 30 (trinta) dias, contados a
          partir da data de encerramento dos Serviços
        </p>
        <p>
          <strong>7.3.</strong> A Semantix tem o direito de alterar, encerrar ou
          adicionar Acordos de Nível de Serviço periodicamente, a seu exclusivo
          critério, de acordo com a Cláusula 10 destes Termos.
        </p>
        <h3>8. Valores e Pagamento</h3>
        <p>
          <strong>8.1.</strong> Os valores, taxas e despesas devidas pelo
          Usuário serão calculados e faturados mensalmente pela Semantix, com
          base na utilização do Serviço. O pagamento dos valores, taxas e
          despesas deverá ser feito pelo Usuário, sem compensação, dedução ou
          retenção de qualquer espécie.
        </p>
        <p>
          <strong>8.2. Forma de Pagamento.</strong> O Usuário poderá escolher
          fazer o pagamento por meio de (i) emissão de fatura/Ordem de Compra ou
          (ii) sistema de pagamento online oferecido na plataforma Open Galaxy
          (MOIP).
        </p>
        <p>
          <strong>8.3 Multa por Atraso.</strong> Caso haja atraso no pagamento,
          o Usuário estará sujeito à cobrança de juros mensais de 1,5% sobre o
          valor devido. Caso a fatura/Ordem de Compra esteja atrasada mais de 30
          dias, a Semantix poderá, a seu exclusivo critério, suspender a conta
          do Usuário até que a fatura/Ordem de Compra seja paga em sua
          totalidade.
        </p>
        <p>
          <strong>8.4. Impostos e Tributos.</strong> Fica aqui estabelecido que
          os tributos e demais encargos fiscais que forem devidos, direta ou
          indiretamente, em virtude dos valores pagos ou recebidos em
          decorrência destes Termos serão de responsabilidade do contribuinte
          assim definido na norma tributária, sem direito a reembolso. A
          previsão desta cláusula não abrange os Impostos Indiretos.
        </p>
        <p>
          <strong>8.5. Impostos Indiretos.</strong> A Semantix poderá cobrar do
          Usuário eventuais Impostos Indiretos que esteja autorizados a cobrar,
          exceto quando o Usuário esteja comprovadamente isento desse pagamento.
        </p>
        <h3>9. Garantias</h3>
        <p>
          <strong>9.1.</strong> Na extensão permitida pela legislação em vigor,
          o Serviço é fornecido “no estado em que se encontra” (as is) e
          “conforme a disponibilidade”, sem garantias de qualquer espécie.
        </p>
        <p>
          <strong>9.2.</strong> O Serviço é fornecido de forma online e sem
          nenhuma garantia de funcionamento referente a forma, período,
          condição, equipamento ou situações especiais de uso para os quais não
          foi inicialmente planejado.
        </p>
        <h3>10. Acordo de Nível de Serviço e Suporte Técnico</h3>
        <p>
          <strong>10.1.</strong> Levando-se em consideração que, na área de
          T.I., não existe garantia integral (100%) de nível de serviço, o
          Acordo de Nível de Serviço (Service Level Agreement ou SLA) estabelece
          os níveis de desempenho técnico da plataforma, sendo certo que tal
          acordo não representa diminuição de responsabilidade, mas indicador de
          excelência técnica.
        </p>
        <p>
          <strong>10.2. SLA.</strong> Desde que observadas as condições
          previstas nestes Termos, a Semantix declara que possui condição
          técnica de oferecer e manter um SLA de disponibilidade da plataforma,
          por 99,5 % do tempo, em cada mês civil, ressalvadas as seguintes
          hipóteses:
        </p>
        <ul>
          <li>
            Falha na conexão proveniente do provedor de acesso à Internet, sem
            culpa da Semantix;
          </li>
          <li>
            Por culpa exclusiva do Usuário em não conseguir acessar a plataforma
            e/ou os Serviços;
          </li>
          <li>Suspensão dos Serviços em razão de ordem judicial;</li>
          <li>
            Interrupções para ajustes técnicos ou para manutenção, bem como,
            aquelas avisadas com antecedência;
          </li>
          <li>
            Intervenções necessárias para evitar ataques ou tentativa de ataque
            hacker/DDoS ou para cessar algum ataque já iniciado, ou
          </li>
          <li>Casos Fortuitos ou de Força Maior.</li>
        </ul>
        <p>
          <strong>10.3. Desconto Proporcional.</strong> O não atingimento do SLA
          proposto em cada mês, gerará para o Usuário o direito de receber um
          desconto proporcional ao não atingimento no pagamento subsequente à
          data da comunicação de descumprimento.
        </p>
        <p>
          <strong>10.4.</strong> A comunicação de descumprimento do SLA deverá
          ser formalizada no prazo máximo de 15 (quinze) dias da constatação
          desse descumprimento e encaminhada, pelo Usuário, por meio dos canais
          de atendimento da plataforma ou pelo e-mail{" "}
          <a href="mailto:opengalaxy@opengalaxy.io">opengalaxy@opengalaxy.io</a>
          .
        </p>
        <p>
          <strong>10.5.</strong> O desconto a será concedido de acordo com a
          tabela abaixo:
        </p>
        <table>
          <thead>
            <tr>
              <td>Disponibilidade</td>
              <td>Desconto</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>94,5%</td>
              <td>10%</td>
            </tr>
            <tr>
              <td>89,5%</td>
              <td>20%</td>
            </tr>
            <tr>
              <td>84,5%</td>
              <td>30%</td>
            </tr>
            <tr>
              <td>79,5%</td>
              <td>40%</td>
            </tr>
            <tr>
              <td>74,5%</td>
              <td>50%</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>10.6.</strong> Caso o Serviço/plataforma fique fora do ar
          acima de 25,5% do tempo em qualquer mês, fica facultado ao Usuário
          pleitear a rescisão unilateral dos Serviços, recebendo o valor pago,
          ou ficar dispensado da próxima mensalidade de pagamento.
        </p>
        <p>
          <strong>10.7.</strong> As diferentes versões do OpenGalaxy possuem
          níveis de Atendimento distintos, conforme apresentado na tabela
          abaixo:
        </p>
        <table className="table-first">
          <thead>
            <tr>
              <td />
              <td>Content</td>
              <td>Content</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Descrição</td>
              <td>Ambientes não Produção</td>
              <td>Ambientes de Produção</td>
            </tr>
            <tr>
              <td>Status e Notificações do Ambiente</td>
              <td>Monitoramento online</td>
              <td>Monitoramento online</td>
            </tr>
            <tr>
              <td>Suporte Técnico</td>
              <td>Atendimento durante horário comercial por e-mail</td>
              <td>Atendimento durante horário comercial por e-mail</td>
            </tr>
            <tr>
              <td>Atendimento Online e Chamado</td>
              <td>Um contato principal / Casos ilimitados</td>
              <td>Um contato principal / Casos ilimitados</td>
            </tr>
            <tr>
              <td>Severidade do caso/ Tempo de Resposta</td>
              <td>
                Instruções Gerais: {"<"}24 horas úteis Falha no sistema: {"<"}8
                horas úteis
              </td>
              <td>
                Instruções Gerais: {"<"}24 horas úteis Falha no sistema: {"<"}8
                horas úteis
              </td>
            </tr>
            <tr>
              <td>Architecture Support</td>
              <td>Acesso durante o horário comercial por e-mail</td>
              <td>
                Acesso durante o horário comercial por e-mail e videoconferência
              </td>
            </tr>
          </tbody>
        </table>
        <h3>11. Indenização</h3>
        <p>
          <strong>11.1.</strong> O Usuário concorda em defender, indenizar e
          proteger a Semantix, bem como seus parceiros, fornecedores,
          licenciadores, diretores, distribuidores e agentes contra quaisquer
          danos, perdas, responsabilidades e despesas resultantes de qualquer
          demanda ou ação judicial que possa surgir de violação destes Termos ou
          efeito de quaisquer mensagens que o Usuário tenha distribuído usando
          os Serviços.
        </p>
        <p>
          <strong>11.2.</strong> O Usuário entende e concorda que a Semantix tem
          o direito de requerer judicialmente indenização por perdas e danos se
          o Usuário utilizar os Serviços para propósitos ilegais.
        </p>
        <h3>12. Limitação de Responsabilidade</h3>
        <p>
          <strong>12.1.</strong> A Semantix não será responsável por danos ou
          qualquer prejuízo incidental, especial, indireto ou consequente,
          incluindo, mas não se limitando a, prejuízos por lucros cessantes,
          perda de chance, perda, falha de transmissão ou recepção de dados, não
          continuidade do negócio ou qualquer outro prejuízo comercial,
          decorrentes ou relacionados ao uso ou inabilidade do Usuário em usar
          os Serviços por qualquer motivo. Sob nenhuma circunstância a
          responsabilidade integral da Semantix com relação ao Usuário pelos
          eventuais danos mencionados excederá o total pago pelo Usuário pelo
          uso do Serviço durante os 12 meses que antecederam o surgimento da
          responsabilidade.
        </p>
        <h3>13. Vigência e Hipóteses de Rescisão</h3>
        <p>
          13.1. Os Termos de Uso entrarão em vigor na Data de Vigência e
          permanecerão em vigor até que sejam rescindidos de acordo com o
          previsto nesta Cláusula{" "}
        </p>
        <p>
          13.2. O Usuário tem o direito de encerrar o seu acesso aos Serviços
          mediante envio de notificação para a Semantix e/ou do encerramento de
          sua conta mediante o mecanismo de encerramento de conta fornecido no
          site da plataforma OpenGalaxy.
        </p>
        <p>
          13.3. A Semantix poderá rescindir os Termos, por qualquer motivo,
          mediante notificação enviada ao e-mail associado à conta, com
          antecedência de 30 dias.
        </p>
        <p>
          <strong>13.4. Rescisão por Justa Causa.</strong>
        </p>
        <p>
          (i) Qualquer das Partes poderá encerrar os Termos por justa causa se a
          outra Parte descumprir materialmente as obrigações aqui previstas, e
          tal descumprimento permaneça não solucionado por 30 dias a partir da
          notificação do descumprimento enviada pela outra Parte.
        </p>
        <p>
          (ii) A Semantix poderá rescindir os Termos de imediato, mediante
          notificação encaminhada ao Usuário se: (a) o relacionamento da
          Semantix com os terceiros parceiros, que fornecem os softwares ou
          tecnologias utilizadas nos Serviços, terminar ou exigir que mudemos a
          forma que fornecemos o software ou tecnologia no Serviço; (b) houver
          exigência legal ou determinação judicial que determinem o encerramento
          dos Serviços.
        </p>
        <p>
          <strong>13.5.</strong> Na Data de Rescisão, com exceção do previsto na
          Cláusula 13.7, todos os direitos e deveres do Usuário estabelecidos
          aqui serão extintos.
        </p>
        <p>
          <strong>13.6.</strong> O Usuário permanecerá responsável por todas as
          taxas e despesas incorridas até a Data de Rescisão.
        </p>
        <p>
          <strong>13.7.</strong> Os itens referentes às Cláusulas de Propriedade
          Intelectual, Garantia, Limitação de Responsabilidade, Indenização e
          Confidencialidade permanecerão em vigor de acordo com seus próprios
          termos.
        </p>
        <h3>14. Alterações nos Termos</h3>
        <p>
          <strong>14.1.</strong> A Semantix poderá alterar os presentes Termos,
          incluindo as Políticas relacionadas ao Serviço, a qualquer tempo,
          disponibilizando a versão atualizada no site da plataforma OpenGalaxy
          e notificando o Usuário por meio de (i) uma publicação no site da
          plataforma no momento do login e/ou (ii) uma notificação encaminhada
          ao e-mail associado à conta do Usuário.
        </p>
        <p>
          <strong>14.2.</strong> A comunicação acima não desobriga o Usuário de
          verificar regularmente o site da plataforma OpenGalaxy para manter-se
          informado sobre eventuais atualizações dos Termos.
        </p>
        <p>
          <strong>14.3.</strong> Ao continuar utilizando os Serviços oferecidos,
          após eventual alteração, o Usuário concorda em estar vinculado aos
          Termos e Condições Gerais de Uso alterados.
        </p>
        <h3>15. Confidencialidade</h3>
        <p>
          <strong>15.1.</strong> O Usuário poderá utilizar as nossas Informações
          Confidenciais somente com relação ao seu uso dos Serviços, conforme
          permitido nestes Termos. O Usuário não revelará as Informações
          Confidenciais da Semantix durante a Vigência do Termo ou a qualquer
          tempo durante o prazo de 3 anos após a Data de Rescisão. O Usuário
          tomará todas as medidas razoáveis para impedir a divulgação,
          disseminação ou uso não autorizado das Informações Confidenciais da
          Semantix, incluindo, no mínimo, as medidas que o Usuário tomaria para
          proteger as suas próprias informações confidenciais.
        </p>
        <h3>16. Disposições Gerais</h3>
        <p>
          <strong>16.1. Cessão.</strong> O Usuário não poderá ceder, nem de
          qualquer forma transferir, estes Termos ou qualquer direito ou
          obrigação decorrente deles sem o prévio e expresso consentimento da
          Semantix. Qualquer cessão ou transferência em desacordo com esta
          cláusula será considerada nula.
        </p>
        <p>
          <strong>16.2.</strong> Inexistência de Vínculo. Ao aceitar estes
          Termos, o Usuário concorda que inexiste qualquer relação de agência,
          mandato, franquia ou vínculo trabalhista, societário, de parceria ou
          associativo entre as Partes.
        </p>
        <p>
          <strong>16.3.</strong> Acordo Integral. Estes Termos (junto com
          quaisquer termos e condições para serviços específicos, incluindo a
          Política de Privacidade) contém o acordo integral entre as Partes em
          relação ao Serviço e substituem todos os eventuais acordos anteriores
          entre as Partes.
        </p>
        <p>
          <strong>16.4.</strong> Renúncia e Independência das Cláusulas.
          Qualquer renúncia tácita, nestes Termos, não será considerada renúncia
          adicional ou contínua a esse ou a qualquer outro termo, e a omissão
          por parte da Semantix em não executar qualquer direito ou cláusula de
          acordo com estes Termos não constituirá renúncia a tal direito ou
          cláusula.
        </p>
        <p>
          <strong>16.5.</strong> Se qualquer cláusula destes Termos for
          considerada inválida pela autoridade competente, a invalidade de tal
          cláusula não afetará a validade das cláusulas restantes, que
          permanecerão integralmente válidas e em vigor.
        </p>
        <p>
          <strong>16.6. Caso Fortuito ou Força Maior.</strong> A Semantix não
          responsabilizamos por eventuais atrasos ou descumprimentos de qualquer
          obrigação aqui estabelecida, quando o atraso ou descumprimento se der
          em decorrência de causas além do nosso controle, incluindo casos
          fortuitos e força maior, nos termos do Art. 393 do Código Civil
          Brasileiro.
        </p>
        <p>
          <strong>16.7. Lei Aplicável.</strong> Estes Termos e as relações
          existentes entre as Partes serão regidas pelas leis da República
          Federativa do Brasil.
        </p>
        <p>
          <strong>16.8. Foro.</strong> As Partes elegem, de forma irrevogável e
          irretratável, o foro da Comarca da Cidade de São Paulo, Estado de São
          Paulo, para dirimir quaisquer dúvidas ou controvérsias oriundas destes
          Termos, com a exclusão de qualquer outro, por mais privilegiado que
          seja.
        </p>
      </div>
    </div>
  </div>
);

export default TermsOfService;
