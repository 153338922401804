import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import Menu from "../../components/Menu/Menu";
import SectionHeader from "../../new-components/SectionHeader";
import MessagePurchase from "../../new-components/Plugins/MessagePurchase";
import Table, { TableRow, TableCol } from "../../new-components/Table";
import PurchaseReview from "../../new-components/Plugins/PurchaseReview";

import actionAPI from "../../actions/actionAPI";
import actionSimpleApi from "../../actions/actionSimpleAPI";

import "./Plugins.css";

class Plugins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      credit_card: false,
      plugins: [],
      environments: [],
      services: [],
      space_service: [],
      terms_agree: false,
      user_level: props.user_level
    };
  }
  componentDidMount() {
    this.props.getUser(this.props.id_token);
    this.props.getEnvironments(this.props.id_token);
    this.props.getPlugins(this.props.id_token);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      plugins: nextProps.plugins,
      environments: nextProps.environments,
      credit_card: nextProps.credit_card,
      user_level: nextProps.user_level
    });
  }
  selectPlugin(env, id, checked) {
    this.setService(id, checked);
    this.setSpaceService(env, id, checked);
  }
  setService(id, checked) {
    const { services } = this.state;
    if (checked) {
      let temp = services.filter(item => item.id_category === id);
      if (temp.length) {
        temp[0].qtd_spaces++;
        this.setState({
          services: [
            ...temp,
            ...services.filter(item => item.id_category !== id)
          ]
        });
      } else {
        this.setState({
          services: [{ id_category: id, qtd_spaces: 1 }, ...services]
        });
      }
    } else {
      let temp = services.filter(item => item.id_category === id);
      if (temp[0].qtd_spaces > 1) {
        temp[0].qtd_spaces--;
        this.setState({
          services: [
            ...temp,
            ...services.filter(item => item.id_category !== id)
          ]
        });
      } else {
        this.setState({
          services: [...services.filter(item => item.id_category !== id)]
        });
      }
    }
  }
  setSpaceService(env, id, checked) {
    const { space_service } = this.state;
    if (checked) {
      let temp = space_service.filter(item => item.id_environment === env);
      if (temp.length) {
        temp[0].services.push(id);
        this.setState({
          space_service: [
            ...temp,
            ...space_service.filter(item => item.id_environment !== env)
          ]
        });
      } else {
        this.setState({
          space_service: [
            { id_environment: env, services: [id] },
            ...space_service
          ]
        });
      }
    } else {
      let temp = space_service.filter(item => item.id_environment === env);
      if (temp.length) {
        temp[0].services = temp[0].services.filter(item => item !== id);
        if (temp[0].services.length) {
          this.setState({
            space_service: [
              ...temp,
              ...space_service.filter(item => item.id_environment !== env)
            ]
          });
        } else {
          this.setState({
            space_service: [
              ...space_service.filter(item => item.id_environment !== env)
            ]
          });
        }
      }
    }
  }
  purchase() {
    this.setState({
      loading: true
    });
    actionAPI(this.props.id_token, "/marketplace/purchase", {
      services: this.state.services,
      "space-service": this.state.space_service
    })
      .then(resp => {
        this.setState({
          loading: false
        });
        Swal.fire({
          title: "Plugin was successfully purchased.",
          text: "Our specialists will contact you.",
          type: "success",
          confirmButtonText: "Go to Spaces"
        }).then(result => {
          if (result.value) {
            this.props.history.push({ pathname: "/environments" });
          }
        });
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Something went wrong!"
        });
      });
  }
  askPlugin(name) {
    this.setState({
      loading: true
    });
    actionAPI(this.props.id_token, "/marketplace/monthly_plugin", {
      plugin: name
    })
      .then(resp => {
        this.setState({
          loading: false
        });
        Swal.fire({
          title: `${name} was requested Successfully`,
          text: "Our Specialists will contact you",
          type: "success"
        });
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: "Something went wrong!"
        });
      });
  }
  render() {
    const {
      plugins,
      credit_card,
      environments,
      services,
      user_level
    } = this.state;
    return (
      <div className="Plugins main-holder">
        <Menu />
        <div className="content-holder">
          <div className={`wrapper ${this.state.loading ? "loading" : ""}`}>
            <h2 className="title-page">Plugins</h2>
            <MessagePurchase
              credit_card={credit_card}
              environments={!!environments.length}
              user_level={user_level}
            />
            <SectionHeader
              title="Special analytics plugins"
              description="Ask our team for plugins according to your necessity. Here are some plugins that you can ask for."
            />

            <div className="cards">
              <div className="Plugin_Plus">
                <div className="container-img">
                  <img src="/static/power_bi.png" />
                </div>
                <div className="title">
                  <span className="name">Power BI</span>
                  <span className="category">Analytics</span>
                </div>
                <span className="description">
                  Explore {"&"} analyze data visually through customized
                  dashboards.
                </span>
                <button
                  className="ask-button"
                  onClick={() => this.askPlugin("Power BI")}
                >
                  Ask for Power BI
                </button>
              </div>
              <div className="Plugin_Plus">
                <div className="container-img">
                  <img src="/static/knime.png" />
                </div>
                <div className="title">
                  <span className="name">KNIME</span>
                  <span className="category">Analytics+AI</span>
                </div>
                <span className="description">
                  Create {"&"} simplify data science applications and services
                  by connecting boxes and arrows.
                </span>
                <button
                  className="ask-button"
                  onClick={() => this.askPlugin("KNIME")}
                >
                  Ask for KNIME
                </button>
              </div>
            </div>
            <SectionHeader
              title="Add plugins to your Spaces"
              description="Extend your Spaces with technologies elected by our specialists."
            />
            <div className="cards">
              {plugins.map(item => (
                <div
                  className={`Plugin card_${item.id_category}`}
                  key={item.id_category}
                >
                  <div className="title">
                    <span className="name">{item.name}</span>
                    <span className="category">{item.category}</span>
                  </div>
                  <span className="description">{item.description}</span>
                </div>
              ))}
            </div>

            {credit_card & !!environments.length & (user_level <= 2) ? (
              <React.Fragment>
                <SectionHeader
                  title="Spaces"
                  description="Define services purchased for each space."
                />

                <div className="SpacesPlugins">
                  <Table
                    header={[
                      ["Space", 40],
                      ...plugins.map(item => [item.name, 60 / plugins.length])
                    ]}
                  >
                    {environments.map(item => (
                      <TableRow
                        key={item.id_environment}
                        cssClass="SpacePlugin"
                      >
                        <TableCol size={40}>{item.name}</TableCol>
                        {plugins.map(_item => (
                          <TableCol size={20} key={_item.id_category}>
                            <input
                              type="checkbox"
                              value={_item.id_category}
                              onChange={el => {
                                this.selectPlugin(
                                  item.id_environment,
                                  _item.id_category,
                                  el.target.checked
                                );
                              }}
                            />
                          </TableCol>
                        ))}
                      </TableRow>
                    ))}
                  </Table>
                </div>
                <SectionHeader
                  title="Purchase Review"
                  description="Review your finantial costs. You'll be billed once."
                />

                <PurchaseReview
                  id_token={this.props.id_token}
                  services={services}
                />

                <div className="agreeCheckbox">
                  <input
                    type="checkbox"
                    id="agree"
                    onChange={el => {
                      this.setState({ terms_agree: el.target.checked });
                    }}
                  />
                  <label htmlFor="agree">I agree with the review and the</label>{" "}
                  <a href="/terms-of-service" target="_blank">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="/privacy-policy" target="_blank">
                    Privacy policy
                  </a>
                  .
                </div>

                <p className="purchasing-message">
                  By purchasing selected plugins, our specialists will contact
                  you.
                </p>

                <div
                  className={`row_button ${
                    this.state.terms_agree && this.state.services.length
                      ? ""
                      : "disable"
                  }`}
                >
                  <button
                    className="main-button"
                    onClick={() => {
                      this.purchase();
                    }}
                  >
                    Purchase Plugins
                  </button>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  environments: state.environments,
  plugins: state.plugins,
  id_token: state.id_token,
  credit_card: state.credit_card,
  user_level: state.user_level
});

const mapDispatchToProps = dispatch => {
  return {
    getPlugins: token => {
      actionAPI(token, "/marketplace/list").then(resp => {
        dispatch({
          type: "PLUGINS",
          data: resp
        });
      });
    },
    getUser: token => {
      actionSimpleApi(token, "/account/login").then(resp => {
        if (resp.status === 200) {
          dispatch({
            type: "HAS_CREDIT_CARD",
            data: resp.data.credit_card
          });
        }
      });
    },
    getEnvironments: token => {
      actionAPI(token, "/company/accounts", null, "get").then(resp => {
        dispatch({
          type: "UPDATE_ENVIRONMENTS",
          data: resp
        });
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plugins);
