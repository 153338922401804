import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Toggle.css'

class Toggle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toogle: props.enabled,
    }
  }

  handleClick(){
    this.setState({ toogle: !this.state.toogle }, ()=> {
      this.props.onClick(this.state.toogle)
    })
  }

  render() {
    return (
      <button
        className={`Toggle ${this.state.toogle ? 'enabled' : 'disabled'}`}
        onClick={this.handleClick.bind(this)}
      />
    )
  }
}

Toggle.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func
}

Toggle.defaultProps = {
  enabled: false,
  onClick: () => false
}

export default Toggle;
