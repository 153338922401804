import React, { Component } from 'react';
import { Link } from "react-router-dom";

class CreateSpaceButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 10,
    }
  }
  availableButton() {
    return (
      <Link to="/create-environment" className="main-button">
        Create Space
      </Link>
    )
  }
  disableButton() {
    return (
      <span></span>
    )
  }
  checkAvailability() {
    return(((this.state.limit - this.props.quantity) > 0) || (typeof(this.props.quantity) === "undefined"))
  }
  button() {
    if (this.checkAvailability()) {
      return(this.availableButton());
    } else {
      return(this.disableButton());
    }
  }
  render() {
    return (this.button())
  }
}
export default CreateSpaceButton