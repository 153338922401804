import React from "react";
import { Link } from "react-router-dom";

const MessagePurchase = ({ credit_card, environments, user_level }) => (
  <div className="MessagePurchase">
    {!credit_card & !environments & user_level < 2 ? (
      <span>
        To Purchase a Plugin, you have to{" "}
        <Link to="/add-credit-card">Add a Credit Card</Link> and{" "}
        <Link to="/create-environment">Create a Space</Link>.
      </span>
    ) : (
      ""
    )}
    {!credit_card & environments & user_level < 2 ? (
      <span>
        To Purchase a Plugin, you have to{" "}
        <Link to="/add-credit-card">Add a Credit Card</Link>.
      </span>
    ) : (
      ""
    )}
    {credit_card & !environments & user_level < 3 ? (
      <span>
        To Purchase a Plugin, you have to{" "}
        <Link to="/create-environment">Create a Space</Link>.
      </span>
    ) : (
      ""
    )}
  </div>
);

export default MessagePurchase;
